<template>
  <v-app>
    <v-main style="position: relative">
      <CartFloatingButton v-if="!disableCartFloatingButton" />
      <v-fade-transition hide-on-leave>
        <router-view />
      </v-fade-transition>
      <WhatsappButton />
    </v-main>
  </v-app>
</template>

<script>
import WhatsappButton from '@/components/common/WhatsappButton'
import CartFloatingButton from '@/components/common/CartFloatingButton'

export default {
  name: 'App',
  components: {
    WhatsappButton,
    CartFloatingButton,
  },
  computed: {
    disableCartFloatingButton() {
      return this.$route.meta.disableCartFloatingButton
    },
  },
}
</script>
