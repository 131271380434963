<template>
  <v-dialog v-model="value" max-width="800px" persistent scrollable>
    <v-card>
      <v-toolbar dense color="primary" flat height="70px">
        <v-toolbar-title class="white--text">Exames</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          hide-details
          append-icon="mdi-magnify"
          placeholder="Pesquisar"
          background-color="white"
          outlined
          dense
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-row v-if="isLoading">
          <v-col cols="12">
            <v-skeleton-loader type="list-item-avatar"> </v-skeleton-loader>
          </v-col>
          <v-col cols="6" v-for="n in 10" :key="n">
            <v-skeleton-loader type="list-item"> </v-skeleton-loader>
          </v-col>
        </v-row>
        <v-data-iterator v-else :items="todosExames" :search="search">
          <template #default="{ items }">
            <v-row no-gutters>
              <v-col v-for="item in items" :key="item.id_exame" cols="12" md="6" lg="6">
                <v-checkbox
                  :input-value="
                    idExamesSelecionados.includes(item.id_exame) || examesAdicionais.includes(item.id_exame)
                  "
                  :disabled="idExamesSelecionados.includes(item.id_exame)"
                  color="primary"
                  :label="`${item.nome} - R$ ${item.preco.replace('.', ',')}`"
                  @change="addOrRemoveExamesAdicionais(item)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-card-text>
      <v-card-actions class="d-flex flex-column flex">
        <v-alert type="error" :value="alert"> Você não possui nenhum exame selecionado </v-alert>
        <div>
          <v-btn color="error" class="mr-2" @click="$emit('close')">Fechar</v-btn>
          <v-btn color="success" @click="addExames()">Adicionar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import agendamentoService from '@/services/agendamento'

export default {
  props: {
    value: {
      required: true,
    },
    idExamesSelecionados: {
      type: Array,
    },
  },
  data() {
    return {
      search: '',
      isLoading: false,
      todosExames: [],
      examesAdicionais: [],
      alert: false,
    }
  },
  computed: {
    ...mapGetters('agendamento', ['clinica']),
  },
  methods: {
    async buscaExames() {
      this.isLoading = true
      try {
        const { data } = await agendamentoService.buscaExames(this.clinica)
        this.todosExames = data
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    addOrRemoveExamesAdicionais(exame) {
      const index = this.examesAdicionais.findIndex(item => item.id_exame === exame.id_exame)
      if (index === -1) {
        this.alert = false
        exame.isAdded = true
        this.examesAdicionais.push(exame)
      } else {
        this.examesAdicionais.splice(index, 1)
      }
    },
    addExames() {
      if (this.examesAdicionais.length === 0) {
        this.alert = true
      } else {
        this.$emit('add-exames', this.examesAdicionais)
      }
    },
  },
  mounted() {
    this.buscaExames()
    this.examesAdicionais = []
  },
}
</script>

<style></style>
