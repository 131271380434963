import { isEmpty } from 'lodash'

export const SET_ERROR_ON_SNACKBAR = 'SET_ERROR_ON_SNACKBAR'
export const HIDE_ALL_MESSAGES = 'HIDE_ALL_MESSAGES'
export const SET_SUCCESS_ON_SNACKBAR = 'SET_SUCCESS_ON_SNACKBAR'

const state = {
  statuses: {
    errorOnSnackbar: false,
    successOnSnackbar: false,
    infoOnSnackbar: false,
    errorMessage: '',
  },
}

const mutations = {
  [SET_ERROR_ON_SNACKBAR]: (state, { value, errorMessage }) => {
    state.statuses.errorOnSnackbar = value
    state.statuses.errorMessage = isEmpty(errorMessage) ? 'Undefined message' : errorMessage
  },
  [SET_SUCCESS_ON_SNACKBAR]: (state, { value, successMessage }) => {
    state.statuses.successOnSnackbar = value
    state.statuses.successMessage = successMessage
  },
  [HIDE_ALL_MESSAGES]: state => {
    state.statuses.errorOnModal = false
    state.statuses.errorOnSnackbar = false
  },
}

const getters = {
  statuses: state => state.statuses,
}

const actions = {
  showErrorOnSnackbar({ commit }, errorMessage) {
    commit(HIDE_ALL_MESSAGES)
    if (typeof errorMessage === 'string') {
      commit(SET_ERROR_ON_SNACKBAR, { value: true, errorMessage })
    } else {
      commit(SET_ERROR_ON_SNACKBAR, { value: true, errorMessage: JSON.stringify(errorMessage) })
    }
  },
  hideErrorOnSnackbar({ commit }) {
    commit(SET_ERROR_ON_SNACKBAR, { value: false })
  },
  showSuccessOnSnackbar({ commit }, successMessage) {
    commit(HIDE_ALL_MESSAGES)
    commit(SET_SUCCESS_ON_SNACKBAR, { value: true, successMessage })
  },
  hideSuccessOnSnackbar({ commit }) {
    commit(SET_SUCCESS_ON_SNACKBAR, { value: false })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
