import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#005DFF',
            }
        }
    },
    lang: {
    locales: { pt },
    current: 'pt'
    },
    icons: {
        iconfont: 'mdi'
    }
});
