<template>
  <v-form ref="form" @submit.prevent>
    <v-row v-if="questionario.perguntas && questionario.perguntas.length > 0" class="mb-10">
      <v-col
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
        :class="$vuetify.breakpoint.smAndDown ? 'align-center' : 'align-left'"
      >
        <v-btn @click="previousQuestion" color="primary" rounded :disabled="currentIndex === 0">
          <span class="capitalize">Anterior</span>
        </v-btn>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="align-center">
        <component
          :is="questionario.perguntas[currentIndex].input"
          :type="questionario.perguntas[currentIndex].type"
          :text="questionario.perguntas[currentIndex].text"
          v-model="questionario.respostas[questionario.perguntas[currentIndex].id]"
          @question-changed="verifyQuestion = true"
        />
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 3"
        :class="$vuetify.breakpoint.smAndDown ? 'align-center' : 'align-right'"
      >
        <v-btn
          color="primary"
          rounded
          @click="nextQuestion"
          :disabled="currentIndex === questionario.perguntas.length - 1 || !verifyQuestion"
        >
          <span class="capitalize">Próxima</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate'
// eslint-disable-next-line import/no-extraneous-dependencies
import agendamentoService from '@/services/agendamento'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import Radio from './QuestionarioRadio.vue'

export default {
  mixins: [validationMixin, agendamentoMixin],
  components: {
    Radio,
  },
  props: {
    funcionario: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      questionario: {},
      currentIndex: 0,
      verifyQuestion: false,
    }
  },
  methods: {
    async getQuestionarioExpressPerguntas() {
      try {
        const { data } = await agendamentoService.getQuestionarioExpressPerguntas()
        this.questionario = data
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      }
    },
    validate() {
      this.funcionario.questionario = this.questionario

      const todasRespondidas = Object.values(this.questionario.respostas).every(resposta => resposta.value !== null)

      if (!todasRespondidas) {
        this.showErrorOnSnackbar('Responda todo o questionário para continuar')
        return false
      }

      return this.$refs.form.validate()
    },
    previousQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1
      }
      this.$emit('current-question', {
        currentIndex: this.currentIndex,
        lastIndex: this.questionario.perguntas.length - 1,
      })
    },
    nextQuestion() {
      this.verifyQuestion = false
      this.$emit('current-question', {
        currentIndex: this.currentIndex,
        lastIndex: this.questionario.perguntas.length - 2,
      })
      if (this.currentIndex < this.questionario.perguntas.length - 1) {
        this.currentIndex += 1
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 5
  },
  async mounted() {
    await this.getQuestionarioExpressPerguntas()

    this.$emit('current-question', {
      currentIndex: this.currentIndex,
      lastIndex: this.questionario.perguntas.length - 1,
    })

    if (this.funcionario.questionario) {
      this.questionario.respostas = this.funcionario.questionario.respostas
    }
  },
}
</script>

<style scoped>
.question-row {
  align-items: center;
  justify-content: space-between;
}

.align-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.align-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
