<template>
  <v-snackbar :value="value" @input="hideSuccessOnSnackbar" color="success" :timeout="5000" multi-line top>
    <span v-show="value">{{ message }}</span>
    <template v-slot:action>
      <v-btn text @click.stop="hideSuccessOnSnackbar"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: ['value', 'message'],
}
</script>
