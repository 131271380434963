<template>
  <transition name="shaketext">
    <div :key="step.number" class="border card" :class="{ 'card--active': active }">
      <div class="card__step">
        <v-icon class="card__step__icon" color="white">{{ step.icon }}</v-icon>
      </div>
      <div class="card__body ml-5">
        <div class="card__body__title mb-2">
          <div class="step-title">{{ step.title }}</div>
        </div>
        <div class="card__body__text step-text">
          {{ step.body }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    step: {
      required: true,
    },
    active: {
      default: false
    }
  },
  computed: {
    ...mapState('agendamento', ['actualStep']),
  }
}
</script>

<style lang="scss">
$primary: #005DFF;
.step-title {
  font-size: 1.3rem;
}
.step-text {
  font-size: 0.9rem
}
.card {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 120px;
  opacity: 0.4;

  &__step {
    position: relative;
    &:before {
      display: block;
      content: '';
      height: 100%;
      width: 0px;
      position: absolute;
      left: calc(50% - 1px);
      border-left: 2px dotted white;
      z-index: 1;
    }
    &::after {
      display: block;
      content: '';
      height: 100%;
      width: 0px;
      position: absolute;
      left: calc(50% - 2px);
      border-left: 4px dotted $primary;
      top: 8px;
      z-index: 2;
    }
    &__icon {
      background: $primary;
      z-index: 4;
    }
  }

  &--active {
    opacity: 1;
  }

  &__body {
    max-width: 15rem;
  }
}
.shaketext-enter-active {
  animation: shake 0.9s;
}
.shaketext-leave-to, .shaketext-leave-active{
  display: none;
}
</style>
