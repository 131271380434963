<template>
  <v-dialog v-model="dialog" max-width="800px" persistent scrollable>
    <v-card height="80vh">
      <v-card-text>
        <div class="text-center my-5">
          Estes exames podem ser marcados de forma totalmente online. Escolha um horário para o atendimento
        </div>
        <v-col cols="12" md="12">
          <HorariosSlider @update-data="buscarHorarios" />
        </v-col>
        <template v-if="loadingHorarios">
          <v-row>
            <v-col cols="12" md="4" v-for="n in 9" :key="n">
              <v-skeleton-loader type="list-item-avatar, list-item-three-line"></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
        <template v-else-if="medicos.length > 0">
          <v-col v-for="medico in medicos" :key="medico.id" cols="12" md="4" class="mb-3">
            <MedicoCard
              :medico="medico"
              v-if="!loadingHorarios"
              :horario="horario"
              @select-horario="horario = $event"
            />
          </v-col>
        </template>
        <v-col cols="12" v-else> Não encontramos nenhum horário disponível nesse dia :( </v-col>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn color="primary" large rounded @click="dialog = false">
          Cancelar
          <v-icon class="ml-1" small>mdi-close</v-icon>
        </v-btn>
        <v-btn color="primary" large rounded @click="confirmHorario()">
          Confirmar
          <v-icon class="ml-1" small>mdi-check</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from 'date-fns'
import agendamentoService from '@/services/agendamento'
import HorariosSlider from '../components/HorariosSlider.vue'
import MedicoCard from '../components/MedicoCard'

export default {
  name: 'EscolherHorario',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    funcionarioHorario: {
      required: true,
    },
  },
  data() {
    return {
      loadingHorarios: true,
      medicos: [],
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    horario: {
      get() {
        return this.funcionarioHorario
      },
      set(value) {
        this.$emit('select-horario', value)
      },
    },
  },
  components: {
    HorariosSlider,
    MedicoCard,
  },
  methods: {
    confirmHorario() {
      if (!this.horario) {
        this.showErrorOnSnackbar('É obrigatório escolher um horário.')
      } else {
        this.$emit('confirm-horario')
      }
    },
    async buscarHorarios(dataSelecionada) {
      try {
        this.loadingHorarios = true
        const { data } = await agendamentoService.buscaHorarios(format(dataSelecionada, 'yyyy-MM-dd'))
        this.medicos = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingHorarios = false
      }
    },
  },
}
</script>

<style scoped>
.v-dialog {
  height: 500px !important;
}
</style>
