<template>
  <div class="horarios-table d-flex flex-row flex">
    <v-tabs v-model="indexDataSelected" show-arrows @change="$emit('update-data', datas[indexDataSelected])">
      <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
      <v-tab v-for="(data, index) in datas" :key="index">
        <div class="d-flex flex-column data">
          <div class="mb-1">
            {{ getDayName(data) }}
          </div>
          <div>
            {{ data.getDate() + '/' + getMonthName(data) }}
          </div>
        </div>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'HorarioSlider',
  data() {
    return {
      datas: [],
      indexDataSelected: 0,
    }
  },
  methods: {
    gerarDatas() {
      const currentDate = new Date()
      const datas = []
      for (let i = 0; i < 15; i++) {
        const newDate = new Date()
        newDate.setDate(currentDate.getDate() + i)
        datas.push(new Date(newDate))
      }
      this.datas = datas.filter(data => !['sábado', 'domingo'].includes(this.getDayName(data)))
    },
    getDayName(date) {
      return date.toLocaleDateString('pt-br', { weekday: 'long' })
    },
    getMonthName(date) {
      return date.toLocaleDateString('pt-br', { month: 'long' })
    },
  },
  mounted() {
    this.gerarDatas()
    this.$emit('update-data', this.datas[this.indexDataSelected])
  },
}
</script>

<style scoped>
.data {
  font-size: 0.8rem;
}
</style>
