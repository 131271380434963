<template>
  <v-row no-gutters align="center" justify="end">
    <v-col class="d-flex justify-end" cols="6">
      <h5 class="secondary-font">{{ text }}</h5>
      <v-input v-model="model.value" :rules="[v => v !== null || '']">
        <template slot="append" slot-scope="{ attrs }">
          <div v-if="model.value === null" class="red--text" v-bind="attrs">Resposta obrigatória</div>
        </template>
      </v-input>
    </v-col>
    <v-col cols="6" class="d-flex justify-end">
      <v-btn-toggle
        v-model="model.value"
        class="bg-default"
        style="position: relative"
        ref="toggleButton"
        :mandatory="model.value !== null"
      >
        <v-row no-gutters>
          <v-btn
            :value="false"
            active-class="error white--text"
            class="mr-3 text-capitalize secondary-font white"
            outlined
            small
            >Não</v-btn
          >
          <v-btn
            :value="true"
            active-class="success white--text"
            class="text-capitalize secondary-font white"
            outlined
            small
            >Sim</v-btn
          >
        </v-row>
      </v-btn-toggle>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    'model.value': {
      immediate: true,
      handler() {
        if (this.model.value !== null) this.$emit('question-changed')
      },
      deep: true,
    },
  },
}
</script>
