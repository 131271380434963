<template>
  <div v-if="$vuetify.breakpoint.mdAndUp">
    <v-btn class="whatsapp-button" @click="goToWhatsapp()" outlined rounded large elevation="5" color="#5ac34f" dark>
      <v-icon color="#5ac34f">mdi-whatsapp</v-icon>
      <div class="button-text ml-1">Falar com um analista</div>
    </v-btn>
  </div>
  <div v-else>
    <v-btn @click="goToWhatsapp()" class="whatsapp-button" fab large elevation="5" color="#5ac34f" dark>
      <v-icon color="white">mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'WhatsappButton',
  methods: {
    goToWhatsapp() {
      window.open('https://wa.me/5511916577255', '_blank')
    },
  },
}
</script>

<style scoped>
.whatsapp-button {
  background-color: aliceblue;
  position: fixed;
  left: 0;
  bottom: 0;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 20px;
  display: inline-block; /* Thanks to Fanky (https://stackoverflow.com/users/2095642/fanky) */
  text-transform: lowercase;
}
.whatsapp-button:hover {
  transform: scale(1.08);
}
.button-text::first-letter {
  text-transform: uppercase !important;
}
</style>
