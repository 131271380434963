<template>
  <v-menu offset-y nudge-bottom="10">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="cart-floating-btn"
        color="primary"
        rounded
        x-large
        :ripple="false"
        v-bind="attrs"
        v-on="on"
        elevation="12"
      >
        <v-icon left size="24"> mdi-cart-outline </v-icon>
        {{ getValorTotalExames }}
      </v-btn>
    </template>
    <template>
      <v-list two-line v-if="getFuncionarios.length">
        <v-list-item v-for="(funcionario, index) in getFuncionarios" :key="index">
          <v-list-item-content>
            <v-list-item-title v-text="funcionario.nome"></v-list-item-title>
            <v-list-item-subtitle v-text="funcionario.cpf"></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action> {{ getValorExames(index) }} </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Nenhum exame adicionado.</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('agendamento/funcionarios', ['getFuncionarios', 'getValorTotalExames', 'getValorExames']),
  },
}
</script>

<style scoped>
.cart-floating-btn {
  position: absolute;
  right: 15px;
  top: 16px;
}
</style>
