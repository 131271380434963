import { cloneDeep } from 'lodash'

export const SET_FUNCOES = 'SET_FUNCOES'
export const RESET_FUNCOES = 'RESET_FUNCOES'

const defaultFuncao = {
  name: '',
  description: '',
  riscos: [],
  exames: [],
}

const state = {
  funcao: cloneDeep(defaultFuncao),
  busca: {
    funcoes: [],
    filtro: {
      name: '',
    },
  },
}

const mutations = {
  [SET_FUNCOES]: (state, funcoes) => {
    state.busca.funcoes = funcoes
  },
}

const getters = {
  funcoes: state => state.busca.funcoes,
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
