import { mapMutations, mapState } from 'vuex'

// import agendamento from '@/services/agendamento'

export default {
  computed: {
    ...mapState('agendamento', ['actualStep']),
    actualStepWrapper: {
      get() {
        return this.actualStep
      },
      set(value) {
        this.setActualStep(value)
      },
    },
  },
  methods: {
    ...mapMutations('agendamento', {
      setActualStep: 'SET_STEP',
    }),
  },
}
