<template>
  <v-dialog v-model="value" persistent max-width="500px" transition="dialog-transition">
    <v-card>
        <v-card-title class="justify-end text-right">
          <v-icon @click="$emit('close')">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div class="text">
            Atenção, o agendamento dessa clinica é feita por horário agendado, o nosso time entrará em contato com você para confirmar o horário do(s) exame(s).
            <div class="font-weight-800 mt-2">
              Voce deseja contiuar?
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn color="#BE6A6D" medium dark @click="$emit('close')">Cancelar</v-btn>
          <v-btn color="#005DFF" medium dark @click="$emit('continue')">Continuar</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
}
</script>

<style scoped>
.text {
  font-size: 0.95rem;
}
</style>
