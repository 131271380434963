<template>
  <div class="protocolo-list" v-if="exames">
    <div class="protocolo-item justify-space-around" v-for="exame in exames" :key="exame.id_exame">
      <div>
        <v-icon size="25" color="#e3e3e3">mdi-alert-circle-outline</v-icon>
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <div v-on="on" class="exame">
            {{ exame.nome }}
          </div>
        </template>
        <div>
          {{ exame.nome }}
        </div>
      </v-tooltip>
      <div class="exame-exigido">
        <div v-if="exame.isAdded">Opcional</div>
        <div v-else>Exigido</div>
      </div>
      <div class="price">R$ {{ exame.preco }}</div>
      <v-tooltip top v-if="!showOnlyPrice">
        <template v-slot:activator="{ on }">
          <div v-on="on" v-if="!showOnlyPrice">
            <v-icon
              :class="{ 'delete-icon': exame.id_exame !== 305, 'delete-icon-disabled': exame.id_exame === 305 }"
              @click="openDeleteDialog(exame)"
              >mdi-delete</v-icon
            >
          </div>
        </template>
        <div v-if="exame.id_exame === 305">Não é permitido exluir este exame</div>
        <div v-else>Exlcuir exame</div>
      </v-tooltip>
    </div>
    <div v-if="exames" class="d-flex justify-end total-price">Total: R$ {{ getTotalExames() }}</div>
    <div class="protocolo-item" v-if="exames.length === 0">Essa função não possui nenhum exame associado...</div>
    <ConfirmDialog
      title="Deseja excluir esse exame?"
      text="Tem certeza que desej excluir esse exame?"
      v-model="deleteDialog"
      @cancel-button="deleteDialog = false"
      @confirm-button="deleteExame"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/dialogs/ConfirmDialog.vue'

export default {
  props: {
    exames: {
      required: true,
    },
    showOnlyPrice: {
      default: false,
    },
  },
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      deleteDialog: false,
      exameDeleted: null,
    }
  },
  methods: {
    getTotalExames() {
      let soma = 0
      this.exames.forEach(exame => {
        soma += parseFloat(exame.preco)
      })
      return soma.toFixed(2)
    },
    openDeleteDialog(exame) {
      if (exame.id_exame !== 305) {
        this.exameDeleted = exame
        this.deleteDialog = true
      }
    },
    deleteExame() {
      this.$emit('delete-exame', this.exameDeleted)
      this.deleteDialog = false
    },
  },
}
</script>

<style lang="scss" scoped>
.highlighted {
  color: #005dff;
}
.protocolo-list {
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
}
.protocolo-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 5px 0px;
}

.exame {
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px 10px;
  color: #005dff;
  font-weight: 700;
}
.risco {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px 10px;
  color: #005dff;
  font-weight: 700;
}
.exame-exigido {
  color: #cac8c8;
  font-size: 0.8rem;
  margin-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.price {
  font-size: 0.8rem;
}
.funcao {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.total-price {
  margin-top: 20px;
  font-size: 0.9rem;
}
.label {
  color: rgba(0, 0, 0, 0.87);
}
.delete-icon {
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.delete-icon:hover {
  color: #ff0000 !important;
  cursor: pointer !important;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}
.delete-icon {
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;

}
.delete-icon-disabled {
  color: #cecece !important;
  cursor: default !important;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>
