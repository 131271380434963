<template>
  <v-row no-gutters align="center" justify="center" class="fill-height agendamento-clinicas" persistent>
    <v-col cols="10">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12" class="head-title mb-2 text-center"> Escolha uma clínica </v-col>
        <v-col cols="12" class="subtitle mb-10 text-center" style="color: grey">
          Escolha uma clínica para o atendimento
        </v-col>
      </v-row>

      <p class="mb-10" v-if="selectedCidade">
        Você escolheu a cidade de <span class="text-link" @click="onClickCidade()">{{ selectedCidade.nome }}</span>
      </p>

      <v-row c>
        <template v-if="isLoading">
          <v-col v-for="(n, index) in 6" :key="index" cols="12" sm="10" md="6" lg="4">
            <v-skeleton-loader type="image, article"></v-skeleton-loader>
          </v-col>
        </template>
        <v-col v-else v-for="item in clinicas" :key="item.id_clinica" cols="12" sm="10" md="6" lg="4">
          <AgendamentoClinicaCard
            :clinica="item"
            @on-click-clinica="selectClinica(item)"
            :active="item.id_clinica === idClinica"
          />
        </v-col>
        <v-col>
          <v-text-field
            ref="dtAgendamento"
            v-if="tipoAgendamento === 'online'"
            label="Data de Agendamento"
            v-mask="'##/##/####'"
            :rules="[
              () => $v.funcionario.dt_agendamento.required || 'Campo obrigatório',
              () => $v.funcionario.dt_agendamento.validDate || 'Data inválida',
            ]"
            v-model="funcionario.dt_agendamento"
            outlined
            dense
          ></v-text-field
        ></v-col>
      </v-row>
    </v-col>
    <HorarioAgendadoAdvice
      v-if="horarioAgendadoAdviceDialog"
      v-model="horarioAgendadoAdviceDialog"
      @close="horarioAgendadoAdviceDialog = false"
      @continue="nextStep()"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import { validationMixin } from 'vuelidate'
import { dateRegex } from '@/utils/constants'
import AgendamentoClinicaCard from '@/components/Agendamento/AgendamentoClinicaCard'
import HorarioAgendadoAdvice from '@/components/Agendamento/dialogs/HorarioAgendadoAdvice'
import { required } from 'vuelidate/lib/validators'
import clinicaService from '@/services/clinica'
import { orderBy } from 'lodash'
import { isPast, parse } from 'date-fns'

export default {
  name: 'AgendamentoClinicas',
  props: {
    cidadeFuncionario: {
      type: Object,
    },
    clinicaFuncionario: {
      type: Object,
    },
    funcionario: {
      type: Object,
    },
  },
  mixins: [agendamentoMixin, validationMixin],
  validations: {
    funcionario: {
      dt_agendamento: {
        required,
        validDate(dt_contratacao) {
          return !isPast(parse(dt_contratacao, 'dd/MM/yyyy', this.refDate)) && dateRegex.test(dt_contratacao)
        },
      },
    },
  },
  components: {
    AgendamentoClinicaCard,
    HorarioAgendadoAdvice,
  },
  data: () => ({
    clinicas: [],
    isLoading: false,
    horarioAgendadoAdviceDialog: false,
    refDate: new Date(),
  }),
  watch: {
    'funcionario.dt_agendamento': {
      immediate: true,
      handler() {
        if (this.$refs.dtAgendamento) {
          this.$emit('valid-date', this.$refs.dtAgendamento.validate())
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('agendamento', ['clinica', 'cidade', 'tipoAgendamento', 'clinica_horarios']),
    idClinica: {
      get() {
        return this.tipoAgendamento === 'online' ? this.clinicaFuncionario.idClinica : this.clinica
      },
      set(value) {
        return this.tipoAgendamento === 'online' ? (this.clinicaFuncionario.idClinica = value) : this.SET_CLINICA(value)
      },
    },
    clinicaHorarios: {
      get() {
        return this.tipoAgendamento === 'online' ? this.clinicaFuncionario.horarios : this.clinica_horarios
      },
      set(value) {
        return this.tipoAgendamento === 'online'
          ? (this.clinicaFuncionario.horarios = value)
          : this.SET_CLINICA_HORARIOS(value)
      },
    },
    selectedCidade() {
      return this.tipoAgendamento === 'online' ? this.cidadeFuncionario : this.cidade
    },
  },
  methods: {
    ...mapMutations('agendamento', ['SET_CLINICA', 'SET_CLINICA_HORARIOS', 'SET_CLINICA_HORARIO_AGENDADO']),
    onClickCidade() {
      if (this.tipoAgendamento === 'online') {
        this.$emit('on-click-cidade')
      } else {
        this.$router.push({ name: 'escolher-agendamento' })
      }
    },
    selectClinica(clinica) {
      this.idClinica = clinica.id_clinica
      this.clinicaHorarios = this.clinicas.find(item => item.id_clinica === this.idClinica).horarios

      this.SET_CLINICA_HORARIO_AGENDADO(clinica.horario_agendado)

      if (clinica.horario_agendado) {
        this.openHorarioAgendadoAdvice()
      } else {
        this.nextStep()
      }
    },
    nextStep() {
      if (this.$refs.dtAgendamento && !this.$refs.dtAgendamento.validate()) {
        this.horarioAgendadoAdviceDialog = false
        return false
      }

      if (this.tipoAgendamento === 'online') {
        this.$emit('clinica-selected')
      } else {
        this.actualStepWrapper = 3
        this.$router.push({ name: 'empresa-cnpj' })
      }
    },
    async getClinicas() {
      if (this.selectedCidade) {
        try {
          this.isLoading = true
          const { data } = await clinicaService.search(this.selectedCidade.id_cidade)
          this.clinicas = orderBy(data.clinicas, ['horario_agendado'], ['asc'])
        } catch (error) {
          console.log(error)
        } finally {
          this.isLoading = false
        }
      }
    },
    openHorarioAgendadoAdvice() {
      this.horarioAgendadoAdviceDialog = true
    },
  },
  mounted() {
    if (this.$refs.dtAgendamento) {
      this.$emit('valid-date', this.$refs.dtAgendamento.validate())
    }
    this.getClinicas()
  },
  beforeMount() {
    this.actualStepWrapper = 2
  },
}
</script>
<style scoped>
.agendamento-clinicas {
  margin-top: 25px;
}
</style>
