<template>
  <v-dialog v-model="value" max-width="800px" persistent>
    <v-card>
      <v-toolbar dense color="primary" flat height="70px">
        <v-toolbar-title class="white--text">Riscos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          hide-details
          append-icon="mdi-magnify"
          placeholder="Pesquisar"
          background-color="white"
          outlined
          dense
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-row v-if="isLoading">
          <v-col cols="12">
            <v-skeleton-loader type="list-item-avatar"> </v-skeleton-loader>
          </v-col>
          <v-col cols="6" v-for="n in 10" :key="n">
            <v-skeleton-loader type="list-item"> </v-skeleton-loader>
          </v-col>
        </v-row>
        <v-data-iterator v-else :items="todosRiscos" :search="search">
          <template #default="{ items }">
            <v-row no-gutters>
              <v-col v-for="item in items" :key="item.id" cols="12" md="6" lg="6">
                <v-checkbox
                  :input-value="idRiscosSelecionados.includes(item.id_risco)"
                  :disabled="idRiscosSelecionados.includes(item.id_risco)"
                  color="primary"
                  :label="`${item.nome}`"
                  @change="addOrRemoveRiscosAdicionais(item)"
                ></v-checkbox>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
        <v-alert type="error" :value="alert"> Você não possui nenhum risco selecionado </v-alert>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="error" @click="$emit('close')">Fechar</v-btn>
        <v-btn color="success" @click="addRiscos()">Adicionar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import agendamentoService from '@/services/agendamento'

export default {
  props: {
    value: {
      required: true,
    },
    idRiscosSelecionados: {
      type: Array,
    },
  },
  data() {
    return {
      search: '',
      isLoading: false,
      todosRiscos: [],
      riscosAdicionais: [],
      alert: false,
    }
  },
  methods: {
    async buscaRiscos() {
      this.isLoading = true
      try {
        const { data } = await agendamentoService.buscaRiscos(this.idClinica)
        this.todosRiscos = data
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    addOrRemoveRiscosAdicionais(risco) {
      const index = this.riscosAdicionais.findIndex(item => item.id_risco === risco.id_risco)
      if (index === -1) {
        this.alert = false
        risco.isAdded = true
        this.riscosAdicionais.push(risco)
      } else {
        this.riscosAdicionais.splice(index, 1)
      }
    },
    addRiscos() {
      if (this.riscosAdicionais.length === 0) {
        this.alert = true
      } else {
        this.$emit('add-riscos', this.riscosAdicionais)
      }
    },
  },
  mounted() {
    this.buscaRiscos()
  },
}
</script>

<style></style>
