var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-row',{staticClass:"fill-height py-15 registro-form",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"head-title mb-2 text-center",attrs:{"cols":"12"}},[_vm._v(" Finalize seu perfil ")]),_c('v-col',{staticClass:"subtitle text-center",attrs:{"cols":"12"}},[_vm._v(" Precisamos de algumas informações sobre você ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2"},[_vm._v("Seu nome completo:")]),_c('v-text-field',{attrs:{"label":"Digite seu nome completo","outlined":"","rules":[
          function () { return _vm.$v.usuario.usuario.required || 'Campo obrigatório'; },
          function () { return _vm.$v.usuario.usuario.minLength || 'Nome deve ter pelo menos 3 caracteres'; } ]},model:{value:(_vm.usuario.usuario),callback:function ($$v) {_vm.$set(_vm.usuario, "usuario", $$v)},expression:"usuario.usuario"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2"},[_vm._v("Seu email:")]),_c('v-text-field',{attrs:{"label":"Digite um email para sua conta","outlined":"","rules":[
          function () { return _vm.$v.usuario.email.required || 'Campo obrigatório'; },
          function () { return _vm.$v.usuario.email.email || 'E-mail inválido'; } ]},model:{value:(_vm.usuario.email),callback:function ($$v) {_vm.$set(_vm.usuario, "email", $$v)},expression:"usuario.email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2"},[_vm._v("Sua senha:")]),_c('v-text-field',{attrs:{"label":"Digite uma senha para sua conta","outlined":"","type":!_vm.showPassword ? 'password' : 'text',"append-icon":!_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[
          function () { return _vm.$v.usuario.senha.required || 'Campo obrigatório'; },
          function () { return _vm.$v.usuario.senha.minLength || 'Senha deve ter pelo menos 6 caracteres'; } ]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.usuario.senha),callback:function ($$v) {_vm.$set(_vm.usuario, "senha", $$v)},expression:"usuario.senha"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2"},[_vm._v("Qual o seu telefone?")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(##) #####-####', '(##) ####-####']),expression:"['(##) #####-####', '(##) ####-####']"}],attrs:{"label":"Digite o seu telefone","outlined":"","rules":[
          function () { return _vm.$v.usuario.fone.required || 'Campo obrigatório'; },
          function () { return _vm.$v.usuario.fone.minLength || 'Telefone inválido'; } ]},model:{value:(_vm.usuario.fone),callback:function ($$v) {_vm.$set(_vm.usuario, "fone", $$v)},expression:"usuario.fone"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2"},[_vm._v("Qual o seu CPF?")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"label":"Digite o seu CPF","outlined":"","rules":[
          function () { return _vm.$v.usuario.cpf.required || 'Campo obrigatório'; },
          function () { return _vm.$v.usuario.cpf.minLength || 'CPF inválido'; },
          function (v) { return _vm.validaCpf(v) || 'CPF inválido'; } ]},model:{value:(_vm.usuario.cpf),callback:function ($$v) {_vm.$set(_vm.usuario, "cpf", $$v)},expression:"usuario.cpf"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2"},[_vm._v("Qual o seu cargo na empresa?")]),_c('v-text-field',{attrs:{"label":"Digite seu cargo na empresa","outlined":"","rules":[function () { return _vm.$v.usuario.cargo.required || 'Campo obrigatório'; }]},model:{value:(_vm.usuario.cargo),callback:function ($$v) {_vm.$set(_vm.usuario, "cargo", $$v)},expression:"usuario.cargo"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"justify-end text-sm-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"elevation-12",attrs:{"color":"primary","rounded":"","x-large":"","loading":_vm.loading},on:{"click":function($event){return _vm.nextStep()}}},[_c('span',{staticClass:"capitalize"},[_vm._v("Seguir")]),_c('v-icon',{attrs:{"size":"20","right":""}},[_vm._v("mdi-chevron-right-circle-outline")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }