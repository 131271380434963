import Axios from 'axios'
// import jsonWebToken from 'jsonwebtoken'

export default {
  emailTokenRedefinirSenha: emailUser => {
    console.log('Dados enviados no emailTokenRedefinirSenha:', { email: emailUser });
    return Axios.post('/recuperar-senha/email', { email: emailUser });
  },
  redefinirSenhaUsuario: (tokenUser, senhaUsuario, confirmacaoSenhaUsuario) =>
    Axios.patch('/recuperar-senha/redefinir-senha', {
      token: tokenUser,
      senha: senhaUsuario,
      confirmacaoSenha: confirmacaoSenhaUsuario,
    }),
}
