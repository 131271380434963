import { EventSourcePolyfill } from 'event-source-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/style.scss'
import './plugins/axios'
import './core'

// eslint-disable-next-line no-global-assign
EventSource = EventSourcePolyfill

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
