const STATUS_EMPRESA = {
  TODAS: -1,
  ATIVA: 1,
  INATIVA: 2,
  BLOQUEADA: 3,
}

const TIPOS_EXAME = [
  { value: 1, text: 'Admissional', slug: 'admissional' },
  { value: 2, text: 'Demissional', slug: 'demissional' },
  { value: 3, text: 'Periódico', slug: 'periodico' },
  { value: 4, text: 'Mudança de Função', slug: 'mudanca-de-funcao' },
  { value: 5, text: 'Retorno ao Trabalho', slug: 'retorno-ao-trabalho' },
]

const SEXOS = [
  { value: 'M', text: 'Masculino' },
  { value: 'F', text: 'Feminino' },
]

const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)(19[0-8][0-9]|199[0-9]|20[0-9]{2}|2100)/

const COMMON_STATUS = {
  ATIVA: 1,
  INATIVA: 2,
}

export { dateRegex, SEXOS, STATUS_EMPRESA, TIPOS_EXAME, COMMON_STATUS }
