import axios from 'axios'
import autenticacaoService from '@/services/autenticacao'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

axios.interceptors.request.use(config => {
    if(autenticacaoService.getToken()) {
        config.headers.Authorization = `Bearer ${autenticacaoService.getToken()}`
    }
    return config
})
