import Axios from 'axios'
import autenticacaoService from './autenticacao'

const URL = '/agendamento-express'
const apiUrl = process.env.VUE_APP_BASE_URL

export default {
  waitingPayment: paymentId => {
    return new EventSource(`${apiUrl}${URL}/pagamentos/${paymentId}/confirmacao`, {
      headers: { Authorization: `Bearer ${autenticacaoService.getToken()}` },
    })
  },
  getPixQrCode: paymentId => Axios.get(`${URL}/pagamentos/${paymentId}/pix/qr-code`),
  getFuncionarios: paymentId => Axios.get(`${URL}/pagamentos/${paymentId}/funcionarios`),
}
