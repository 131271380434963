const getDefaultState = () => {
  return {
    funcionarios: [],
    currentFuncionarioCpf: null,
  }
}

const numberFormat = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const state = getDefaultState()

const mutations = {
  // setFuncionario: (state, funcionario) => (state.funcionario = funcionario),
  addFuncionario: (state, funcionario) => state.funcionarios.push(funcionario ?? funcionario),
  editFuncionario: (state, { funcionario, index }) => (state.funcionarios[index] = funcionario),
  setCurrentFuncionarioCpf: (state, cpf) => (state.currentFuncionarioCpf = cpf),
  setRiscos: (state, { riscos, index }) => (state.funcionarios[index].riscos = riscos),
  setExames: (state, { exames, index }) => (state.funcionarios[index].exames = exames),
  resetState: state => Object.assign(state, getDefaultState()),
  deleteFuncionario: (state, index) => state.funcionarios.splice(index, 1),
}

const getters = {
  getFuncionarios: state => state.funcionarios,
  getCurrentFuncionario: state =>
    state.funcionarios.find(funcionario => funcionario.cpf === state.currentFuncionarioCpf),
  getCurrentFuncionarioIndex: state =>
    state.funcionarios.findIndex(funcionario => funcionario.cpf === state.currentFuncionarioCpf),
  getValorTotalExames: state => {
    let soma = 0
    state.funcionarios.forEach(funcionario => {
      funcionario.exames.forEach(exame => {
        soma += parseFloat(exame.preco)
      })
    })
    return numberFormat.format(soma)
  },
  getValorExames: state => index => {
    const soma = state.funcionarios[index]?.exames.reduce((total, exame) => (total += parseFloat(exame.preco)), 0)
    return soma ? numberFormat.format(soma) : 'R$ 0,00'
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
