<template>
  <v-row class="agendamento-redefinir-senha fill-height" no-gutters justify="center">
    <v-col cols="12" class="pa-5">
    </v-col>
    <v-flex xs5>
      <v-layout column>
        <v-card class="py-6 px-15 elevation-10">
          <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12" class="head-title mb-2 text-center"> Redefina sua senha </v-col>
              <v-col cols="12" class="subtitle text-center"> Digite sua nova senha </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-text-field
          class="mt-4"
          :type="showPassword ? 'text' : 'password'"
          @blur="$v.senha.$touch()"
          @click:append="showPassword = !showPassword"
          autocomplete="new-password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          label="Digite sua nova senha"
          :rules="[
              v => $v.senha.required || 'Digite uma senha.',
              v => $v.senha.minLength || 'A sua senha precisa de pelo menos 6 caracteres.',
              v => $v.senha.testeCaracteresEspeciais || 'A sua senha precisa de pelo menos um caractere especial.',
          ]"
          @keydown.enter="doRedefinirSenha"
          v-model="senha"
          outlined
        />
        <v-text-field
          :type="showPasswordConfirmation ? 'text' : 'password'"
          @blur="$v.confirmacaoSenha.$touch()"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
          autocomplete="new-password"
          :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          label="Confirme sua nova senha"
          :rules="[
              v => $v.confirmacaoSenha.required || 'Repita a senha.',
              v => $v.confirmacaoSenha.sameAsPassword || 'As duas senhas não coincidem.',
          ]"
          @keydown.enter="doRedefinirSenha"
          v-model="confirmacaoSenha"
          outlined
        ></v-text-field>
        <v-flex xs12>
          <v-alert type="error" v-show="hasErrorInService">
           {{ showErrorInService }}
          </v-alert>
          <v-alert type="success" v-show="hasSuccessInService">
           {{ showSuccessInService }}
          </v-alert>
        </v-flex>
        <v-flex xs12>
          <v-layout column align-center mx-10>
            <v-btn class="text-none mb-5" color="primary" block x-large @click="doRedefinirSenha" :loading="loading"
              >Alterar Senha</v-btn
            >
          </v-layout>
        </v-flex>
        </v-card>
      </v-layout>
    </v-flex>
  </v-row>
</template>

<style scoped>
.agendamento-redefinir-senha {
  background: #f0f0f0;
  }
</style>

<script>
import Cookies from 'js-cookie'
import redefinirSenha from '@/services/redefinirSenha'
import redefinirSenhaValidationMixin from '@/mixins/redefinir-senha-validation-mixin'

export default {
  mixins: [redefinirSenhaValidationMixin],
  data: () => ({
    senha: '',
    confirmacaoSenha: '',
    showPassword: false,
    showPasswordConfirmation: false,
    hasErrorInService: false,
    hasSuccessInService: false,
    showErrorInService: '',
    showSuccessInService: '',
    loading: false,
  }),
  computed: {
    Cookies() {
      return Cookies
    },
  },
  methods: {
    async doRedefinirSenha() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          this.Loading = true
          this.hideAllMessages()
          const { data } = await redefinirSenha.redefinirSenhaUsuario(
            this.$route.query.token,
            this.senha,
            this.confirmacaoSenha
          )
          this.hasSuccessInService = true
          this.hasErrorInService = false
          this.showSuccessInService = data.message
          setTimeout(() => {
            this.$router.push({ name: 'agendamento-perfil' });
          }, 2000);
        } catch (e) {
          this.hasSuccessInService = false
          this.hasErrorInService = true
          this.showErrorInService = e.response.data.message
        } finally {
          this.Loading = false
        }
      }
    },
  },
}
</script>
