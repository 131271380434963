<template>
  <div class="agendamento-perfil">
    <v-tabs v-if="!forgotPassword" v-model="tab" centered background-color="transparent">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#login"> Login </v-tab>
      <v-tab href="#registro" :disabled="enable_agendamento_express"> Registro </v-tab>
    </v-tabs>

    <v-tabs-items v-if="!forgotPassword" v-model="tab">
      <v-tab-item value="login">
        <AgendamentoLogin
          :enable_agendamento_express="enable_agendamento_express"
          :id_empresa="id_empresa"
          @next-step="nextStep"
          @show-login-form="resolveShowLoginForm"
          @on-cadastro="tab = 'registro'"
        />
      </v-tab-item>
      <v-tab-item value="registro">
        <AgendamentoRegistro
          @next-step="nextStep"
          @redirect-to-login="redirectToLogin = true"
          @show-login-form="resolveShowLoginForm"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import AgendamentoLogin from './AgendamentoLogin.vue'
import AgendamentoRegistro from './AgendamentoRegistro.vue'
import AgendamentoEnviarTokenEmailForm from './AgendamentoEnviarTokenEmailForm.vue'

export default {
  data() {
    return {
      isLoading: false,
      showLoginForm: true,
      tab: 'login',
      redirectToLogin: false,
      forgotPassword: false,
      enable_agendamento_express: false,
      id_empresa: '',
    }
  },
  mixins: [agendamentoMixin],
  computed: {
    ...mapGetters('agendamento/empresa', ['getEmpresaJaCadastrada', 'getEmpresa']),
  },
  components: {
    AgendamentoRegistro,
    AgendamentoLogin,
    AgendamentoEnviarTokenEmailForm,
  },
  methods: {
    resolveShowLoginForm(value) {
      this.showLoginForm = value
    },
    nextStep() {
      this.actualStepWrapper = 6
      this.$router.push({ name: 'colaborador' })
    },
  },
  watch: {
    redirectToLogin: {
      immediate: true,
      handler() {
        if (this.redirectToLogin) this.tab = 'login'
        this.redirectToLogin = false
      },
      deep: true,
    },
  },
  mounted() {
    this.tab = 'login'
  },
  beforeMount() {
    this.actualStepWrapper = 5
    this.enable_agendamento_express = this.getEmpresa.enable_agendamento_express
    this.id_empresa = this.getEmpresa.id_empresa
  },
}
</script>

<style scoped>
.highlighted:hover {
  text-decoration: underline;
}
.v-tab >>> .v-tabs-bar {
  color: transparent !important;
}

.agendamento-perfil {
  height: 100%;
}

.agendamento-perfil >>> .theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>
