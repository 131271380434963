/**
 * Funções de auxílio para a validação dos dígitos verificadores do CNPJ.
 *
 * Implementação seguindo os requisitos encontrados nesse site https://souforce.cloud/regra-de-validacao-para-cpf-e-cnpj/
 *
 * A validação não garante que o CNPJ exista na base de dados da Receita mas verifica
 * se o CNPJ inserido é válido
 *
 */

let peso = 2

/**
 * Multiplica o número seguindo a regra dos pesos (entre 2 e 9)
 *
 * @param { Integer } num
 * @return { Integer } Valor multiplicado pelo peso
 */
const multiplica = num => {
  const multiplicado = peso * num

  if (peso === 9) {
    peso = 2
  } else {
    peso += 1
  }

  return multiplicado
}

/**
 * @param { Integer } total
 * @param { Integer } num
 * @return { Integer } Total
 */
const soma = (total, num) => total + num

/**
 * Calcula o dígito verificador
 *
 * @param { Integer } num
 * @return { Integer }
 */
const calculaDigito = num => {
  const resto = num % 11

  return resto < 2 ? 0 : 11 - resto
}

/**
 * Formata o CNPJ retirando as pontuações, barras e traços
 *
 * @param { String } cnpj
 * @return { String }
 */
const formataCnpj = cnpj => cnpj.replace(/[^\d]+/g, '')

/**
 * Faz a validação do CNPJ, caso esteja correto retorna o valor
 * e se for incorreto retorna false
 *
 * @param { String } cnpj
 * @return { Boolean }
 */
const validaCnpj = cnpj => {
  const cnpjFormatado = formataCnpj(cnpj)
  let primeirosDigitos = formataCnpj(cnpj).slice(0, -2)

  for (let i = 1; i <= 2; i++) {
    const nums = primeirosDigitos.split('').reverse()

    const resultado = nums.map(multiplica).reduce(soma)

    const digito = calculaDigito(resultado)

    primeirosDigitos += digito

    peso = 2
  }

  if (primeirosDigitos !== cnpjFormatado) {
    return false
  }

  return true
}

export default validaCnpj
