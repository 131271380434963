<template>
  <div>
    <h5 class="head-title text-center">Escolha uma cidade</h5>
    <p class="subtitle text-center mb-15">Escolha a cidade que deseja ser atendido</p>

    <v-form ref="AgendamentoCidadeForm" @submit.prevent="nextStep">
      <CustomAutocomplete
        v-model="selectedCidade"
        :items="cidades"
        @on-search="loadCidades"
        @on-change="verificaCidadeSemClinicas"
        @loading="resolveLoading"
        :loading="loading"
        itemValue="id_cidade"
        itemText="nome"
        :returnObject="true"
        label="Digite o nome da cidade para atendimento"
        noDataText="Ops! Não encontramos nenhuma cidade"
        placeholder="Busque por uma cidade"
      />
    </v-form>
    <div v-if="alert" transition="fade-transition" class="alerta">
      <div class="mb-2 font-weight-black">
        Ainda não temos clínicas credenciadas nessa cidade.
      </div>
      Selecione outra cidade ou entre em contato com nosso suporte através do
      <v-icon class="mr-1" dark small>mdi-whatsapp</v-icon>
      <a href="https://api.whatsapp.com/send?phone=5519991637172" target="_blank" class="white--text">
        (19) 99163-7172</a
      >
      ou mande um email para
      <a href="mailto:atendimento@limer.med.br" class="white--text">atendimento@limer.med.br</a>.
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import agendamentoService from '@/services/agendamento'
import CustomAutocomplete from '@/components/common/CustomAutoComplete.vue'

export default {
  props: {
    cidadeFuncionario: {
      typeo: Object,
    },
  },
  name: 'AgendamentoCidades',
  data: () => ({
    termCidade: '',
    loading: false,
    cidades: [],
    alert: false,
    requiredRule: [v => !!v || 'Este campo é obrigatório.'],
  }),
  components: {
    CustomAutocomplete,
  },
  computed: {
    ...mapGetters('agendamento', ['cidade', 'tipoAgendamento']),
    selectedCidade: {
      get() {
        return this.tipoAgendamento === 'online' ? this.cidadeFuncionario : this.cidade
      },
      set(value) {
        this.tipoAgendamento === 'online' ? this.$emit('select-cidade', value) : this.setCidade(value)
      },
    },
  },
  methods: {
    ...mapMutations('agendamento', {
      setCidade: 'SET_CIDADE',
    }),
    resolveLoading(value) {
      this.loading = value
    },
    async loadCidades(val) {
      this.loading = true
      try {
        const { data } = await agendamentoService.buscaCidades({ nome: val, countClinicas: true })
        this.cidades = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    verificaCidadeSemClinicas(cidade) {
      const checkCidadeClinicas = (cidade || this.selectedCidade)
      if(checkCidadeClinicas) {
        this.alert = checkCidadeClinicas.count_clinicas < 1
        return !this.alert
      }
      return false
    },
  },
  beforeMount() {
    this.actualStepWrapper = 1
  },
  mounted() {
    if (this.selectedCidade) {
      this.cidades.push(this.selectedCidade)
    }
  },
}
</script>

<style scoped>
.alerta {
  font-size: 0.9rem;
  background-color: rgb(248, 149, 0);
  color: white;
  padding: 10px;
  border-radius: 10px;
}
</style>
