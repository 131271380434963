<template>
  <div class="d-flex flex-column justify-center align-center elevation-2 pa-3">
    <div class="d-flex flex-row align-center justify-start">
      <div class="medico-avatar mr-3">
        {{ getInitials(medico.nome) }}
      </div>
      <div>
        {{ medico.nome }}
      </div>
    </div>

    <v-sheet class="py-4 px-1 d-flex flex-row flex-wrap" style="width: 100%">
      <div
        v-for="agenda in medico.horarios"
        :key="agenda.id"
        @click="updateHorarioSelected(agenda.id)"
        class="horario"
        :class="{ active: horario === agenda.id }"
      >
        {{ agenda.horario }}
      </div>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MedicoCard',
  props: ['medico', 'horario'],
  computed: {
    ...mapGetters('agendamento/funcionarios', ['getFuncionarios', 'getCurrentFuncionarioIndex']),
  },
  methods: {
    updateHorarioSelected(id) {
      this.$emit('select-horario', id)
    },
    getInitials(name) {
      const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

      let initials = [...name.matchAll(rgx)] || []

      return (initials = ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase())
    },
    verifyAlredySelectedHorario() {
      const alredySelectedHorario = []
      this.getFuncionarios.forEach((funcionario, index) => {
        if(this.getCurrentFuncionarioIndex !== index) {
          alredySelectedHorario.push(funcionario.idHorario)
        }
      })
      alredySelectedHorario.forEach(horario => {
        const indexHorario = this.medico.horarios.findIndex(item => item.id === horario)
        console.log(indexHorario)
        if (indexHorario !== -1) {
          this.medico.horarios.splice(indexHorario, 1)
        }
      })
    },
  },
  mounted() {
    this.verifyAlredySelectedHorario()
  },
}
</script>

<style>
.medico-avatar {
  border-radius: 50%;
  background-color: #105dff;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.horario {
  padding: 6px;
  margin: 5px;
  font-size: 0.9rem;
  background-color: rgb(202, 196, 196);
  border-radius: 6px;
  color: white;
  cursor: pointer;
  width: 28%;
  text-align: center;
}
.active,
.horario:hover {
  color: blue;
  background-color: rgb(195, 217, 252);
  transform: scale(1.1);
}
</style>
