<template>
  <v-form ref="form">
    <v-row no-gutters align="center" justify="center" class="fill-height py-15 registro-form">
      <v-col cols="12">
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" class="head-title mb-2 text-center"> Finalize seu perfil </v-col>
              <v-col cols="12" class="subtitle text-center"> Precisamos de algumas informações sobre você </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Seu nome completo:</div>
        <v-text-field
          label="Digite seu nome completo"
          outlined
          v-model="usuario.usuario"
          :rules="[
            () => $v.usuario.usuario.required || 'Campo obrigatório',
            () => $v.usuario.usuario.minLength || 'Nome deve ter pelo menos 3 caracteres',
          ]"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Seu email:</div>
        <v-text-field
          label="Digite um email para sua conta"
          outlined
          v-model="usuario.email"
          :rules="[
            () => $v.usuario.email.required || 'Campo obrigatório',
            () => $v.usuario.email.email || 'E-mail inválido',
          ]"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Sua senha:</div>
        <v-text-field
          label="Digite uma senha para sua conta"
          outlined
          v-model="usuario.senha"
          :type="!showPassword ? 'password' : 'text'"
          @click:append="showPassword = !showPassword"
          :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            () => $v.usuario.senha.required || 'Campo obrigatório',
            () => $v.usuario.senha.minLength || 'Senha deve ter pelo menos 6 caracteres',
          ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Qual o seu telefone?</div>
        <v-text-field
          label="Digite o seu telefone"
          outlined
          v-model="usuario.fone"
          v-mask="['(##) #####-####', '(##) ####-####']"
          :rules="[
            () => $v.usuario.fone.required || 'Campo obrigatório',
            () => $v.usuario.fone.minLength || 'Telefone inválido',
          ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Qual o seu CPF?</div>
        <v-text-field
          label="Digite o seu CPF"
          outlined
          v-model="usuario.cpf"
          v-mask="'###.###.###-##'"
          :rules="[
            () => $v.usuario.cpf.required || 'Campo obrigatório',
            () => $v.usuario.cpf.minLength || 'CPF inválido',
            v => validaCpf(v) || 'CPF inválido',
          ]"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <div class="text-subtitle-1 font-weight-bold mt-3 pl-3 mb-2">Qual o seu cargo na empresa?</div>
        <v-text-field
          label="Digite seu cargo na empresa"
          outlined
          v-model="usuario.cargo"
          :rules="[() => $v.usuario.cargo.required || 'Campo obrigatório']"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" class="justify-end text-sm-right">
        <v-btn color="primary" rounded x-large class="elevation-12" @click="nextStep()" :loading="loading">
          <span class="capitalize">Seguir</span>
          <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import autenticacaoService from '@/services/autenticacao'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import validaCpf from '@/utils/validaCpf'

export default {
  mixins: [validationMixin, agendamentoMixin],
  validations: {
    usuario: {
      usuario: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      senha: {
        required,
        minLength: minLength(6),
      },
      cargo: {
        required,
      },
      fone: {
        required,
        minLength: minLength(13),
      },
      cpf: {
        required,
        minLength: minLength(14),
      },
    },
  },
  data() {
    return {
      usuario: {
        usuario: null,
        email: null,
        senha: null,
        cargo: null,
        cpf: null,
        fone: null,
      },
      showPassword: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('agendamento', ['sendLeadsDataToSlack']),

    async nextStep() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      try {
        await autenticacaoService.register(this.usuario)

        this.sendLeadsDataToSlack({
          nome: this.usuario.usuario,
          fone: this.usuario.fone,
          email: this.usuario.email,
          cpf: this.usuario.cpf,
          cargo: this.usuario.cargo,
        })
        this.$emit('next-step')
      } catch (error) {
        console.error(error)

        // Parâmetro gerado no validator "StoreUsuario"
        const { redirectToLogin } = error.response.data

        if (redirectToLogin) {
          this.showErrorOnSnackbar('Este email já está cadastrado! Redirecionando para o login')
          setTimeout(() => this.$emit('redirect-to-login'), 2000)
        } else {
          this.showErrorOnSnackbar(error.response.data.message)
        }
      } finally {
        this.loading = false
      }
    },
    validaCpf(cpf) {
      if (process.env.VUE_APP_ENV === 'DEV') return true
      return validaCpf(cpf)
    },
  },
}
</script>

<style>
.registro-form {
  padding: 0px 20%;
}
</style>
