import Axios from 'axios'
import { extractOnlyNumbers, formatDate } from '@/utils/formatter'

const URL = '/agendamento-express'

const buildSaveFuncionarioAgendamento = (funcionario, idClinica, tipoAgendamento) => ({
  cpf: extractOnlyNumbers(funcionario.cpf),
  cbo: funcionario.cbo.split('-')[0].trim(),
  dt_contratacao: formatDate(funcionario.dt_contratacao),
  dt_agendamento: formatDate(funcionario.dt_agendamento),
  dt_nascimento: formatDate(funcionario.dt_nascimento),
  exame: funcionario.exame,
  exames: funcionario.exames.map(exame => exame.id_exame),
  funcao: funcionario.funcao,
  nome: funcionario.nome,
  riscos: funcionario.riscos.map(risco => risco.id_risco),
  setor: funcionario.setor,
  sexo: funcionario.sexo,
  id_clinica: tipoAgendamento === 'online' ? funcionario.clinica?.idClinica : idClinica,
  id_horario: funcionario.idHorario,
  questionario: funcionario.questionario,
})

export default {
  buscaCidades: ({ nome, countClinicas }) => Axios.get(`${URL}/cidades`, { params: { nome, countClinicas } }),
  buscaFuncoes: nome => Axios.get(`${URL}/funcoes`, { params: { nome } }),
  buscaSetores: nome => Axios.get(`${URL}/setores`, { params: { nome } }),
  buscaCbos: search => Axios.get(`${URL}/cbos`, { params: { search } }),
  verificaCnpj: cnpj => Axios.get(`${URL}/verifica-cnpj/${extractOnlyNumbers(cnpj)}`),
  verificaCpf: (cpf, idEmpresa) =>
    Axios.get(`${URL}/verifica-cpf/${extractOnlyNumbers(cpf)}`, { params: { idEmpresa } }),
  protocolo: (exame, funcao, cbo, setor, questionario, idClinica) =>
    Axios.get(`${URL}/protocolo/${exame}`, { params: { funcao, cbo, setor, questionario, idClinica } }),
  buscaExames: idClinica => Axios.get(`${URL}/exames`, { params: { idClinica } }),
  buscaRiscos: () => Axios.get('riscos'),
  finalizarAgendamento: agendamento => {
    agendamento.funcionarios.forEach((funcionario, index) => {
      agendamento.funcionarios[index] = buildSaveFuncionarioAgendamento(
        funcionario,
        agendamento.idClinica,
        agendamento.tipoAgendamento
      )
    })
    return Axios.post(`${URL}/finalizar`, agendamento)
  },
  enviarGuias: async ({ id, email }) => {
    try {
      const params = { id, email }
      return Axios.post(`${URL}/guias/enviar`, params)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  buscaHorarios: data => Axios.get(`${URL}/horarios/${data}`),
  sendLeadsDataToSlack: data => Axios.post(`${URL}/send-leads-data`, data),
  getQuestionarioExpressPerguntas: () => Axios.get(`${URL}/questionario-perguntas`),
}
