<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-15 sucesso-page">
    <v-col cols="10">
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="head-title mb-2 text-center"> Sucesso, seus agendamentos foram confirmados </v-col>
            <v-col cols="12" class="subtitle text-center"> Envie o link das guias para seus colaboradores </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="10" class="my-10">
      <AgendamentoSucessoCard v-for="funcionario in funcionarios" :key="funcionario.cpf" :funcionario="funcionario" />
    </v-col>
  </v-row>
</template>

<script>
import agendamentoMixin from '@/mixins/agendamentoMixin'
import pagamentoService from '@/services/pagamento.js'
import AgendamentoSucessoCard from './AgendamentoSucessoCard.vue'

export default {
  props: ['paymentId'],
  mixins: [agendamentoMixin],
  components: {
    AgendamentoSucessoCard,
  },
  data: () => ({
    funcionarios: [],
  }),
  methods: {
    async getFuncionarios() {
      try {
        const { data } = await pagamentoService.getFuncionarios(this.paymentId)
        this.funcionarios = data
      } catch (error) {
        console.log(error)
        this.showErrorOnSnackbar(error.response.data.message)
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 8
    this.getFuncionarios()
  },
}
</script>

<style scooped>
.sucesso-page {
  align-content: center;
}
</style>
