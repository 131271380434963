import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import agendamento from './modules/agendamento'
import setor from './modules/setor'
import funcao from './modules/funcao'
import layout from './modules/layout'

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  key: process.env.VUE_APP_VERSION,
  storage: window.localStorage,
  modules: ['agendamento']
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  modules: {
    agendamento,
    setor,
    funcao,
    layout
  },
});
