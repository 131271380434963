var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],ref:"cpf",attrs:{"rules":[
          function () { return _vm.$v.funcionario.cpf.required || 'Campo obrigatório'; },
          function () { return _vm.$v.funcionario.cpf.minLength || 'CPF inválido'; },
          function (v) { return _vm.validaCpf(v) || 'CPF inválido'; } ],"outlined":"","label":"CPF","append-outer-icon":"mdi-magnify"},on:{"click:append-outer":function($event){return _vm.verificaCpf(_vm.funcionario.cpf)},"input":_vm.onCpfInput},model:{value:(_vm.funcionario.cpf),callback:function ($$v) {_vm.$set(_vm.funcionario, "cpf", $$v)},expression:"funcionario.cpf"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","lg":"8"}},[_c('v-text-field',{attrs:{"rules":[
          function () { return _vm.$v.funcionario.nome.required || 'Campo obrigatório'; },
          function () { return _vm.$v.funcionario.nome.minLength || 'Mínimo de 3 caracteres'; } ],"disabled":_vm.lockFields,"label":"Adicione o nome do colaborador","outlined":""},model:{value:(_vm.funcionario.nome),callback:function ($$v) {_vm.$set(_vm.funcionario, "nome", $$v)},expression:"funcionario.nome"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"rules":[function () { return (_vm.$v.funcionario.rg.minLength && _vm.$v.funcionario.rg.maxLength) || 'RG inválido'; }],"outlined":"","disabled":_vm.lockFields,"label":"RG"},model:{value:(_vm.funcionario.rg),callback:function ($$v) {_vm.$set(_vm.funcionario, "rg", $$v)},expression:"funcionario.rg"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"items":_vm.sexos,"rules":[function () { return _vm.$v.funcionario.sexo.required || 'Campo obrigatório'; }],"outlined":"","disabled":_vm.lockFields,"label":"Sexo"},model:{value:(_vm.funcionario.sexo),callback:function ($$v) {_vm.$set(_vm.funcionario, "sexo", $$v)},expression:"funcionario.sexo"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"rules":[
          function () { return _vm.$v.funcionario.dt_nascimento.required || 'Campo obrigatório'; },
          function () { return _vm.$v.funcionario.dt_nascimento.validDate || 'Data inválida'; },
          function () { return _vm.$v.funcionario.dt_nascimento.minimalAge || 'Caso seja Menor Aprendiz, deve ter no mínimo 14 anos'; } ],"outlined":"","disabled":_vm.lockFields,"label":"Data de Nascimento"},model:{value:(_vm.funcionario.dt_nascimento),callback:function ($$v) {_vm.$set(_vm.funcionario, "dt_nascimento", $$v)},expression:"funcionario.dt_nascimento"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"rules":[
          function () { return _vm.$v.funcionario.dt_contratacao.required || 'Campo obrigatório'; },
          function () { return _vm.$v.funcionario.dt_contratacao.validDate || 'Data inválida'; } ],"outlined":"","disabled":_vm.lockFields,"label":"Data de Admissão"},model:{value:(_vm.funcionario.dt_contratacao),callback:function ($$v) {_vm.$set(_vm.funcionario, "dt_contratacao", $$v)},expression:"funcionario.dt_contratacao"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"items":_vm.exames,"rules":[function (v) { return !!v || 'Campo obrigatório'; }],"item-value":"slug","label":"Tipo exame","disabled":_vm.lockFields,"outlined":""},model:{value:(_vm.funcionario.exame),callback:function ($$v) {_vm.$set(_vm.funcionario, "exame", $$v)},expression:"funcionario.exame"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }