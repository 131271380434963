<template>
  <div class="d-flex flex-column step-container">
    <div class="logo">
      <router-link to="/">
        <img width="130" src="../../../assets/logo.png" />
      </router-link>
    </div>
    <div class="mt-auto mb-auto ml-8">
      <div class="step-title mb-10 ml-1">{{ actualStep }} de 9 passos</div>
      <div class="steppers">
        <transition-group name="slide-fade">
          <AgendamentoStepCard
            v-if="getStepByNumber(actualStep)"
            :step="getStepByNumber(actualStep)"
            :active="true"
            key="actualStep"
          />

          <AgendamentoStepCard
            v-if="$route.name !== 'agendamento-sucesso'"
            :step="getStepByNumber(actualStep + 1)"
            class="mb-5"
            key="nextStep"
          />
        </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AgendamentoStepCard from '@/components/Agendamento/steps/AgendamentoStepCard'

export default {
  components: {
    AgendamentoStepCard,
  },
  data: () => ({
    steps: [
      {
        title: 'Escolha a cidade',
        body: 'Escolha a cidade que gostaria de realizar o atendimento',
        icon: 'mdi-compass-outline',
        number: 1,
      },
      {
        title: 'Escolha a clínica',
        body: 'Selecione a clínica para a realização do atendimento',
        icon: 'mdi-hospital-marker',
        number: 2,
      },
      {
        title: 'Insira o CNPJ',
        body: 'Informe o CNPJ que o colaborador será contratado',
        icon: 'mdi-wallet-travel',
        number: 3,
      },
      {
        title: 'Dados da sua empresa',
        body: 'Finalize o cadastro da sua empresa',
        icon: 'mdi-notebook-edit-outline',
        number: 4,
      },
      {
        title: 'Perfil',
        body: 'Crie ou entre sua conta e receba seu resultado de forma rápida e segura',
        icon: 'mdi-account-outline',
        number: 5,
      },
      {
        title: 'Adicione colaboradores',
        body: 'Adicione os colaboradores para realização do exame',
        icon: 'mdi-account-outline',
        number: 6,
      },
      {
        title: 'Resumo dos agendamentos',
        body: 'Veja todos os agendamentos solicitados',
        icon: 'mdi-format-list-checkbox',
        number: 6,
      },
      {
        title: 'Pagamento',
        body: 'Finalize o pagamento desse atendimento',
        icon: 'mdi-apps',
        number: 7,
      },
      {
        title: 'Sucesso',
        body: 'Seus agendamentos estão liberados',
        icon: 'mdi-check-outline',
        number: 8,
      },
    ],
  }),
  computed: {
    offsetPixel() {
      return `-${(this.actualStep - 1) * 120}px`
    },
    ...mapState('agendamento', ['actualStep']),
  },
  methods: {
    getStepByNumber(step) {
      return this.steps.find(item => item.number === step)
    },
  },
}
</script>

<style lang="scss" scoped>
.step-title {
  font-size: 1.3rem;
}

.steppers {
  display: flex;
  flex-direction: column;
  top: 0;
  position: relative;
  transition: top 0.5s;
}

.step-container {
  background: #005dff;
  height: 100%;
  color: #fff;
  position: fixed;
  width: 25%;
}
.logo {
  float: left;
  margin-left: 32px;
  margin-top: 16px;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
