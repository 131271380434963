import Axios from 'axios'
import { isNil } from 'lodash'

export default {
  searchSetores: (idEmpresa, inativas = true) => Axios.get(`/empresas/${idEmpresa}/setores`, { params: { inativas } }),
  searchSetor: idSetor => Axios.get(`/setores/${idSetor}`),
  save: (setor, idEmpresa) => {
    const exames = setor.exames.map(exame => {
      const tipos = exame.tipos.map(tipo => tipo.value)
      return { id: exame.value, validade: exame.validade, tipos }
    })

    const params = {
      nome: setor.name,
      status: setor.status,
      id_empresa: idEmpresa,
      riscos: setor.riscos.map(r => r.value),
      exames,
      descricao: setor.description
    }

    if (!isNil(setor.id)) {
      // atualiza
      return Axios.put(`/setores/${setor.id}`, params)
    }

    return Axios.post('/setores', params)
  },
  updateStatus: (idFuncao, status) => Axios.patch(`/setores/${idFuncao}/status`, { status })
}
