<template>
  <v-fade-transition hide-on-leave>
    <router-view></router-view>
  </v-fade-transition>
</template>

<script>
import agendamentoMixin from '@/mixins/agendamentoMixin'

export default {
  mixins: [agendamentoMixin],
}
</script>
