<template>
  <div class="d-flex flex-column flex background pt-5 resumo-card">
    <div class="mb-5 title-resumo px-5">
      Exame <span class="capitalize">{{ funcionario.exame }}</span>
    </div>
    <v-row>
      <v-col cols="9" lg="9">
        <div class="mb-1 px-5">Dados do colaborador</div>
        <div class="d-flex flex-column text px-5 mb-8">
          <div class="d-flex flex-row mb-1">
            <div class="mr-5">Nome: {{ funcionario.nome }}</div>
            <div class="mr-5">CPF: {{ funcionario.cpf }}</div>
            <div v-if="funcionario.rg">RG: {{ funcionario.rg }}</div>
          </div>
          <div class="d-flex flex-row mb-1">
            <div class="mr-5">Data Nasc: {{ funcionario.dt_nascimento }}</div>
            <div class="mr-5">Data Adm: {{ funcionario.dt_contratacao }}</div>
          </div>
          <div class="d-flex flex-row">
            <div class="capitalize mr-5">Função: {{ funcionario.funcao.toLowerCase() }}</div>
            <div class="capitalize mr-5">Setor: {{ funcionario.setor.toLowerCase() }}</div>
            <div class="capitalize mr-5">CBO: {{ funcionario.cbo.toLowerCase() }}</div>
          </div>
        </div>
      </v-col>
      <v-col cols="3" lg="3" class="px-5">
        <div class="d-flex flex-row justify-space-around">
          <v-btn fab @click="goToEdit(funcionario.cpf)">
            <v-icon large color="#071AFF">mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn fab @click="deleteDialog = true">
            <v-icon large color="#071AFF">mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" v-if="funcionarioIsValid">
        <div class="px-5 mb-2 required">
          Atenção, é necessário que você preencha todas as informações, por favor, atualize os dados do funcionário
        </div>
      </v-col>
    </v-row>
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header class="exames-expansion">
          <v-spacer></v-spacer>
          Ver exames
          <template v-slot:actions>
            <v-icon small color="#4511ff" class="ml-2"> mdi-chevron-down-circle-outline </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="exames-expansion">
          <ExamesList :exames="funcionario.exames" :showOnlyPrice="true" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <ConfirmDialog
      title="Excluir funcionário"
      text="Tem certeza que deseja excluir esse funcionário?"
      v-model="deleteDialog"
      @cancel-button="deleteDialog = false"
      @confirm-button="confirmDeleteFuncionario()"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import ConfirmDialog from '@/components/common/dialogs/ConfirmDialog.vue'
import ExamesList from './ExamesList.vue'

export default {
  name: 'ColaboradorResumoCard',
  props: {
    funcionario: {
      required: true,
    },
  },
  components: {
    ExamesList,
    ConfirmDialog,
  },
  data() {
    return {
      value: null,
      deleteDialog: false,
    }
  },
  computed: {
    ...mapGetters('agendamento/funcionarios', ['getFuncionarios']),
    ...mapGetters('agendamento', ['tipoAgendamento']),
    funcionarioIsValid() {
      return this.tipoAgendamento === 'online' && !(this.funcionario.clinica?.idClinica || this.funcionario.idHorario)
    },
  },
  methods: {
    ...mapMutations('agendamento/funcionarios', ['setCurrentFuncionarioCpf', 'deleteFuncionario']),
    goToEdit(cpf) {
      this.setCurrentFuncionarioCpf(cpf)
      this.$router.push({ name: 'colaborador-dados', query: { edit: 'true' } })
    },
    confirmDeleteFuncionario() {
      const index = this.getFuncionarios.findIndex(funcionario => funcionario.cpf === this.funcionario.cpf)
      this.deleteFuncionario(index)
      this.deleteDialog = false
    },
  },
}
</script>

<style scoped>
.required {
  font-size: 0.9rem;
  color: #ff0000;
  font-weight: 900;
}
.background {
  background-color: #faf9f9;
}
.title-resumo {
  color: #005dff;
}
.text {
  color: rgb(129, 127, 127);
}
.exames-expansion {
  background-color: #ecedf0 !important;
  font-size: 0.9rem !important;
}
.resumo-card {
  width: 100%;
}
.resumo-card >>> .v-expansion-panel-header {
  height: 20px !important;
  min-height: 20px !important;
}
</style>
