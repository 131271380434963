<template>
  <v-form ref="form">
    <v-row>
      <v-col cols="12" lg="4" class="mt-5">
        <v-text-field
          v-model="funcionario.cpf"
          :rules="[
            () => $v.funcionario.cpf.required || 'Campo obrigatório',
            () => $v.funcionario.cpf.minLength || 'CPF inválido',
            v => validaCpf(v) || 'CPF inválido',
          ]"
          outlined
          label="CPF"
          v-mask="'###.###.###-##'"
          append-outer-icon="mdi-magnify"
          @click:append-outer="verificaCpf(funcionario.cpf)"
          @input="onCpfInput"
          ref="cpf"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="8" class="mt-5">
        <v-text-field
          v-model="funcionario.nome"
          :rules="[
            () => $v.funcionario.nome.required || 'Campo obrigatório',
            () => $v.funcionario.nome.minLength || 'Mínimo de 3 caracteres',
          ]"
          :disabled="lockFields"
          label="Adicione o nome do colaborador"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          v-model="funcionario.rg"
          :rules="[() => ($v.funcionario.rg.minLength && $v.funcionario.rg.maxLength) || 'RG inválido']"
          outlined
          :disabled="lockFields"
          label="RG"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
          v-model="funcionario.sexo"
          :items="sexos"
          :rules="[() => $v.funcionario.sexo.required || 'Campo obrigatório']"
          outlined
          :disabled="lockFields"
          label="Sexo"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="funcionario.dt_nascimento"
          :rules="[
            () => $v.funcionario.dt_nascimento.required || 'Campo obrigatório',
            () => $v.funcionario.dt_nascimento.validDate || 'Data inválida',
            () => $v.funcionario.dt_nascimento.minimalAge || 'Caso seja Menor Aprendiz, deve ter no mínimo 14 anos',
          ]"
          outlined
          :disabled="lockFields"
          label="Data de Nascimento"
          v-mask="'##/##/####'"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field
          v-model="funcionario.dt_contratacao"
          :rules="[
            () => $v.funcionario.dt_contratacao.required || 'Campo obrigatório',
            () => $v.funcionario.dt_contratacao.validDate || 'Data inválida',
          ]"
          outlined
          :disabled="lockFields"
          label="Data de Admissão"
          v-mask="'##/##/####'"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="4">
        <v-select
          v-model="funcionario.exame"
          :items="exames"
          :rules="[v => !!v || 'Campo obrigatório']"
          item-value="slug"
          label="Tipo exame"
          :disabled="lockFields"
          outlined
        ></v-select>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { dateRegex, TIPOS_EXAME, SEXOS } from '@/utils/constants'
import { isPast, parse, differenceInYears } from 'date-fns'
import validaCpf from '@/utils/validaCpf'
import agendamento from '@/services/agendamento'
import { mapGetters } from 'vuex'

export default {
  mixins: [validationMixin, agendamentoMixin],
  validations: {
    funcionario: {
      nome: { required, minLength: minLength(3) },
      sexo: { required },
      cpf: { required, minLength: minLength(14) },
      rg: { minLength: minLength(3), maxLength: maxLength(14) },
      dt_nascimento: {
        required,
        validDate(dt_nascimento) {
          return isPast(this.transformDate(dt_nascimento)) && dateRegex.test(dt_nascimento)
        },
        minimalAge(dt_nascimento) {
          const age = differenceInYears(new Date(), this.transformDate(dt_nascimento))

          return age >= 14
        },
      },
      dt_contratacao: {
        required,
        validDate(dt_contratacao) {
          return dateRegex.test(dt_contratacao)
        },
      },
    },
  },
  props: {
    funcionario: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    exames: TIPOS_EXAME,
    sexos: SEXOS,
    refDate: new Date(),
    lockFields: true,
  }),
  computed: {
    ...mapGetters('agendamento/empresa', ['getCnpj', 'getEmpresa']),
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    validaCpf(cpf) {
      if (process.env.VUE_APP_ENV === 'DEV') return true
      return validaCpf(cpf)
    },
    transformDate(date) {
      return parse(date, 'dd/MM/yyyy', this.refDate)
    },
    onCpfInput() {
      if (!this.$refs.cpf.validate()) {
        this.lockFields = true
        // Reseta os dados do funcionário caso o CPF seja nulo
        if (!this.funcionario.cpf) {
          this.$emit('update-funcionario', {})
        }

        return
      }
      this.verificaCpf(this.funcionario.cpf)
    },
    async verificaCpf(val) {
      try {
        const { data } = await agendamento.verificaCpf(val, this.getEmpresa.id_empresa)
        this.lockFields = false
        if (data.pessoa) {
          this.$emit('update-funcionario', { ...this.funcionario, ...data.pessoa })
        }
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 5
  },
}
</script>

<style scoped></style>
