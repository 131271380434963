import Axios from 'axios'

const URL = '/agendamento-express'

export default {
  search: (idCidade = null) =>
    Axios.get(`${URL}/clinicas`, {
      params: {
        cidade: idCidade,
      },
    }),
}
