<template style="v-application">
  <v-row no-gutters align="center" justify="center" class="fill-height py-15 registro-form">
    <v-col cols="8">
      <v-card class="pa-10 elevation-10">
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12" class="head-title mb-2 text-center"> Redefina sua senha </v-col>
              <v-col cols="12" class="subtitle text-center"> Digite seu e-mail e enviaremos o link para recuperação de senha </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form">
          <div class=" d-flex flex-column justify-center">
            <v-text-field
             class="mt-4"
              label="Email da sua conta"
              outlined
              v-model="email"
              :rules="[
                () => $v.email.required || 'Campo obrigatório',
                () => $v.email.email || 'E-mail inválido',
              ]"
            ></v-text-field>
          </div>
        </v-form>
        <v-flex xs12>
          <v-alert type="error" v-show="hasErrorInService">
            {{ showErrorInService }}
          </v-alert>
          <v-alert type="success" v-show="hasSuccessInService">
            {{ showSuccessInService }}
          </v-alert>
        </v-flex>
        <v-row no-gutters justify="center">
          <v-spacer></v-spacer>
          <v-btn rounded x-large class="mx-6 elevation-12" to="/perfil" :loading="loading">
            <v-icon color="primary" size="20" left>mdi-chevron-left-circle</v-icon>
            <span class="capitalize primary--text">Voltar</span>
          </v-btn>
          <v-btn color="primary" rounded x-large class="elevation-12" @click="doEnvioToken" :loading="loading">
            <span class="capitalize">Seguir</span>
            <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import redefinirSenha from '@/services/redefinirSenha'

export default {
  mixins: [validationMixin, agendamentoMixin],
  validations: {
    email: {
        required,
        email,
      },
    },
  data() {
    return {
      email: null,
      showPassword: false,
      loading: false,
      hasErrorInService: false,
      hasSuccessInService: false,
      showErrorInService: '',
      showSuccessInService: '',
    }
  },
  methods: {
    async doEnvioToken() {
      this.$v.$touch()
      console.log(this.email)
      if (!this.$v.$invalid) {
        try {
          this.loading = true
          const { data } = await redefinirSenha.emailTokenRedefinirSenha(this.email)
          this.hasSuccessInService = true
          this.hasErrorInService = false
          this.showSuccessInService = data.message
          console.log(data.message)
          this.loading = false
        } catch (e) {
          this.hasSuccessInService = false
          this.hasErrorInService = true
          this.showErrorInService = e.response.data.message
        } finally {
          this.loading = false
        }
      }
    }
  }
}

</script>

<style scoped>
.registro-form {
  padding: 0px 20%;
  background: #f0f0f0;
}
.highlighted:hover {
  text-decoration: underline;
}
</style>
