<template>
  <div class="d-flex flex-row sucesso-card">
    <div class="d-flex flex-column width-60">
      <span class="nome"> {{ funcionario.nome }} </span>
      <span class="cpf mb-2"> {{ funcionario.cpf | cpf }} </span>
      <div v-if="funcionario.clinica">
        <div class="d-flex flex-row clinica-info">
          <div class="d-flex flex-row align-start mr-2">
            <v-icon class="mr-2" color="#2776ff">mdi-map-marker-outline</v-icon>
            <div>
              {{ funcionario.clinica.endereco }} <br />
              {{ funcionario.clinica.numero }}, {{ funcionario.clinica.bairro }} - {{ funcionario.clinica.cidade.nome }}
            </div>
          </div>
          <div class="d-flex flex-column clinica-horario">
            <div class="d-flex flex-row">
              <v-icon color="#2776ff" class="mr-2">mdi-calendar</v-icon>
              <span class="capitalize">{{ funcionario.clinica.horarios }}</span>
            </div>
          </div>
        </div>
        <div class="mt-3" v-if="funcionario.clinica.horario_agendado">
          Atenção, esse atendimento foi marcado em uma clinica com horário agendado, aguarde a Limer entrar em contato
          para confirmar os agendamentos
        </div>
      </div>
      <div v-else-if="funcionario.horario">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-start mr-2">
            <v-icon class="mr-2" color="#2776ff">mdi-clock-outline</v-icon>
            <div class="capitalize">{{ dataAgendamentoOnline }} <br /></div>
          </div>
          <div class="d-flex flex-row align-start mt-1 mr-2">
            <v-icon class="mr-2" color="#2776ff">mdi-map-marker-outline</v-icon>
            <div>Agendamento Online</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column ml-8 justify-center align-center flex">
      <v-btn
        width="11rem"
        large
        color="#f0f0f0"
        class="button-text-default mb-5"
        rounded
        elevation="0"
        @click="copyLink()"
        :class="{ 'link-copiado': linkCopiado }"
      >
        <v-icon :color="linkCopiado ? '#005dff' : '#b8b9c8'">mdi-link-variant</v-icon>
        <span>{{ linkCopiado ? 'Link copiado' : 'Copiar link' }}</span>
      </v-btn>
      <v-btn
        width="11rem"
        large
        color="#f0f0f0"
        class="button-text-default"
        rounded
        elevation="0"
        @click="sendEmailDialog = true"
        :class="{ 'email-enviado': emailEnviado }"
      >
        <v-icon :color="emailEnviado ? '#fff' : '#b8b9c8'">mdi-share-outline</v-icon>
        <span>{{ emailEnviado ? 'E-mail enviado' : 'Enviar e-mail' }}</span>
      </v-btn>
    </div>
    <v-dialog transition="dialog-transition" width="800px" v-model="sendEmailDialog">
      <v-card style="border-radius: 16px !important">
        <v-card-text class="pa-15 justify-center align-center">
          <div class="dialog-title text-lg-center">
            Enviar guia do colaborador<br />
            <span class="highlighted">{{ funcionario.nome }}</span> por email
          </div>
          <v-form ref="form">
            <v-text-field
              label="Digite o e-mail do colaborador aqui"
              outlined
              v-model="emailColaborador"
              :rules="[
                () => $v.emailColaborador.required || 'Campo obrigatório',
                () => $v.emailColaborador.email || 'E-mail inválido',
              ]"
            ></v-text-field>
          </v-form>
          <v-btn color="primary" rounded x-large class="float-right" @click="sendEmail()" :loading="isLoading">
            <span class="capitalize">Seguir</span>
            <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import agendamentoService from '@/services/agendamento'

export default {
  props: {
    funcionario: {
      required: true,
    },
  },
  mixins: [validationMixin],
  validations: {
    emailColaborador: {
      required,
      email,
    },
  },
  computed: {
    dataAgendamentoOnline() {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      const date = new Date(this.funcionario.horario.inicio)
      return `${date.toLocaleDateString('pt-br', options)}, ${date.toLocaleTimeString('pt-br', {
        hour: 'numeric',
        minute: 'numeric',
      })}`
    },
  },
  data() {
    return {
      isLoading: false,
      emailEnviado: false,
      linkCopiado: false,
      sendEmailDialog: false,
      emailColaborador: null,
    }
  },
  methods: {
    async sendEmail() {
      if (!this.$refs.form.validate()) return

      try {
        this.isLoading = true
        await agendamentoService.enviarGuias({ id: this.funcionario.id_guia, email: this.emailColaborador })
      } catch ({ response }) {
        this.showErrorOnSnackbar(response.data.message)
      } finally {
        this.sendEmailDialog = false
        this.emailEnviado = true
        this.isLoading = false
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.funcionario.guia)
      this.linkCopiado = true
    },
  },
}
</script>

<style scoped>
.button-text-default {
  text-transform: capitalize;
  color: #3a3a3a !important;
  font-weight: bolder;
}
.email-enviado {
  color: #fff !important;
  background-color: #35ad00 !important;
}
.link-copiado {
  color: #005dff !important;
  background-color: #d0e1ff !important;
}
.email-enviado:hover {
  cursor: not-allowed !important;
}
.highlighted {
  color: #005dff;
}
.dialog-title {
  font-size: 1.3rem;
  color: black;
  line-height: 1.5rem;
  margin-bottom: 5%;
}
.sucesso-card {
  border-radius: 16px;
  border: 1px solid #005dff;
  padding: 23px;
  margin-bottom: 20px;
  color: #9c9da1;
  font-size: 0.9rem;
}
.cpf {
  color: #9c9da1;
}
.clinica-horario {
  max-width: 500px;
}
.width-60 {
  width: 60% !important;
}
</style>
