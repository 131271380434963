<template>
  <v-row no-gutters :class="{ 'full-height': $vuetify.breakpoint.mdAndUp }">
    <SnackbarError v-model="statuses.errorOnSnackbar" :message="statuses.errorMessage" />
    <SnackbarSuccess v-model="statuses.successOnSnackbar" :message="statuses.successMessage" />
    <v-col cols="12" md="3" :class="{ 'full-height': $vuetify.breakpoint.mdAndUp }" class="hidden-sm-and-down">
      <AgendamentoSteps />
    </v-col>
    <v-col cols="12" md="9">
      <v-fade-transition hide-on-leave>
        <router-view />
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import AgendamentoSteps from '@/components/Agendamento/steps/AgendamentoSteps'
import SnackbarError from '@/components/common/SnackbarError'
import SnackbarSuccess from '@/components/common/SnackbarSuccess'

export default {
  components: {
    AgendamentoSteps,
    SnackbarError,
    SnackbarSuccess,
  },
  computed: {
    paddingSteps() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'pl-0'
        case 'sm':
          return 'px-3'
        case 'md':
          return 'pl-10'
        case 'lg':
          return 'pl-10'
        case 'xl':
          return 'pl-10'
        default:
          return 'pl-10'
      }
    },
    ...mapGetters('layout', ['statuses']),
  },
  methods: {
    goTo(step) {
      this.showLoading()
      this.$router.push({ name: step })
      this.hideLoading()
    },
  },
}
</script>
<style scoped>
.full-height {
  height: 100% !important;
}
</style>
