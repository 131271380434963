import { validationMixin } from 'vuelidate'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

const caracteresEspeciais = /[#?!@$%^&*-]/

export default {
  mixins: [validationMixin],
  validations: {
    senha: {
      required,
      minLength: minLength(6),
      testeCaracteresEspeciais(value) {
        return caracteresEspeciais.test(value)
      },
    },
    confirmacaoSenha: {
      required,
      sameAsPassword: sameAs('senha'),
    },
  },
}
