const getDefaultState = () => {
  return {
    empresaJaCadastrada: false,
    segmentos: [],
    segmento: null,
    empresa: {
      razao_social: null,
      cnpj: null,
      qtde_funcionarios: 0,
      enable_agendamento_express: false,
      id_empresa: null,
    },
  }
}

const state = getDefaultState()

const mutations = {
  setEmpresa: (state, empresa) => (state.empresa = empresa),
  setCnpj: (state, cnpj) => (state.empresa.cnpj = cnpj),
  setEmpresaJaCadastrada: (state, value) => (state.empresaJaCadastrada = value),
  setSegmentos: (state, segmentos) => (state.segmentos = segmentos),
  setSegmento: (state, segmento) => (state.segmento = segmento),
  resetState: state => Object.assign(state, getDefaultState()),
  setEmpresaId: (state, empresaId) => (state.empresa.empresaId = empresaId),
  setQtdeFuncionarios: (state, qtdeFuncionarios) => (state.empresa.qtde_funcionarios = qtdeFuncionarios),
}

const getters = {
  getCnpj: state => state.empresa.cnpj,
  getSegmento: state => state.segmento,
  getSegmentos: state => state.segmentos,
  getEmpresaJaCadastrada: state => state.empresaJaCadastrada,
  getEmpresa: state => state.empresa,
  getEmpresaId: state => state.empresa.empresaId,
  getQtdeFuncionarios: state => state.empresa.qtde_funcionarios,
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
