<template>
  <v-container class="fill-height d-flex align-center flex-column justify-center">
    <div class="head-title">Indicar colaborador</div>
    <div class="subtitle mb-10">Para quem você deseja agendar esses exames?</div>
    <div>
      <v-img class="mt-15 mb-5" max-height="200" max-width="200" src="../../../assets/funcionario.png"></v-img>
    </div>
    <div>
      <v-btn text color="#3700ff" class="capitalize" @click="adicionarColaborador()" x-large
        >Adicionar colaborador</v-btn
      >
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'AdicionarColaborador',
  methods: {
    adicionarColaborador() {
      this.$router.push({ name: 'colaborador-dados' })
    },
  },
}
</script>

<style scoped></style>
