<template>
  <v-autocomplete
    v-model="model"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :item-value="itemValue"
    :item-text="itemText"
    :label="label"
    cache-items
    :rules="[v => !!v || 'Campo obrigatório']"
    outlined
    :return-object="returnObject"
    :placeholder="placeholder"
    append-icon="mdi-chevron-down-circle"
    :hide-no-data="!search || loading || search.length < 3"
    :no-data-text="noDataText"
    class="custom-autocomplete"
    @change="$emit('on-change', $event)"
  >
    <template #append v-if="loading">
      <v-progress-circular indeterminate color="primary" size="30"></v-progress-circular>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    loading: {
      default: false,
    },
    value: [String, Object],
    items: Array,
    label: String,
    itemValue: {
      default: null,
    },
    itemText: {
      default: null,
    },
    returnObject: {
      default: false,
    },
    noDataText: {
      default: 'Ops, não encontramos nenhum dado',
    },
    placeholder: {
      default: 'Faça uma busca',
    },
  },
  data: () => ({
    search: '',
    timerId: null,
  }),
  computed: {
    validateSearch() {
      return !(!this.search || this.search.length < 3)
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    search(val) {
      if (!this.validateSearch) return
      this.$emit('loading', true)
      clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.$emit('on-search', val)
      }, 500)
    },
  },
}
</script>

<style scoped>
.custom-autocomplete >>> .v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
