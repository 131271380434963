<template>
  <v-snackbar :value="value" @input="hideErrorOnSnackbar" color="error" :timeout="6000" multi-line top>
    <span v-show="value">{{ message }}</span>
    <template v-slot:action>
      <v-btn text @click.stop="hideErrorOnSnackbar"> Fechar </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    message: String,
    value: Boolean,
  },
}
</script>
