<template>
  <div class="agendamento-clinica-card d-flex flex-column flex" :class="{ 'agendamento-clinica-card-active': active  }"
   @click="$emit('on-click-clinica')">
    <v-img class="mb-5" src="@/assets/clinicas_default.png"></v-img>
    <div class="text-left clinica-name mx-3">
      <span class="capitalize">{{ clinica.nome.toLowerCase() }}</span>
    </div>
    <div class="mx-3">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
            <div v-on="on" class="endereco capitalize my-1">
                {{ clinica.endereco }}, nº {{ clinica.numero }}, {{ clinica.bairro.toLowerCase() }}
            </div>
        </template>
        <div class="capitalize">
          {{ clinica.endereco }}, nº {{ clinica.numero }}, {{ clinica.bairro.toLowerCase() }}
        </div>
      </v-tooltip>
    </div>
    <div class="my-3 mx-3">
      <span v-if="clinica.horario_agendado" class="font-weight-bold my-2">
        <v-chip color="#fbff7c">Horário Agendado</v-chip>
      </span>
      <template v-else>
        <v-menu
          v-model="menu"
          bottom
          right
          transition="scale-transition"
          origin="top left"
        >
          <template v-slot:activator="{ on }">
            <v-chip
              color="#7cafff"
              v-on="on"
            >
             Ver horários disponíveis <v-icon>mdi-chevron-right</v-icon>
            </v-chip>
          </template>
          <v-card width="300">
            <v-list dark>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-clock</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Horários</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    @click="menu = false"
                  >
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item @click="() => {}">
                <v-list-item-content>{{ clinica.horarios || '08:00 às 12:00' }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
    </div>
    <div class="selecionar-clinica-link pointer mb-3 mx-3" @click="$emit('on-click-clinica')">
      Selecionar essa clínica
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clinica: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
    };
  }
}
</script>

<style scoped>
.agendamento-clinica-card {
  border-radius: 5px;
  margin-bottom: 30px;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  border: none;
  -webkit-box-shadow: 0 1px 7px rgba(0,0,0,.05);
  box-shadow: 0 1px 7px rgba(0,0,0,.05);
}
.agendamento-clinica-card:hover, .agendamento-clinica-card-active  {
  cursor: pointer;
  transform: scale(1.04);
  border: 1px solid #006bf2 !important;
}

.selecionar-clinica-link {
  color: #3b79ff;
  font-weight: 500;
  font-size: 0.95rem;
}
.endereco {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.5rem;
  color: #898995;
  font-size: 0.95rem;
}
.clinica-name {
  color: #636363;
  font-stretch: condensed;
  font-weight: bolder;
}

@keyframes crescendo {
  0%   {transform: scale(1);}
  100% {transform: scale(1.05);}
}
</style>
