<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable persistent>
    <v-card height="80vh">
      <v-card-text>
        <div class="text-center my-5">
          Estes exames não podem ser marcados de forma totalmente online. Escolha uma clinica para realizar alguns
          procedimentos adicionais
        </div>
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step > 1" step="1"> Selecionar cidade </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2"> Selecionar clínica </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <AgendamentoCidades
                :cidadeFuncionario="funcionario.cidade"
                ref="AgendamentoCidade"
                @select-cidade="$emit('select-cidade', $event)"
              />
            </v-stepper-content>

            <v-stepper-content step="2">
              <AgendamentoClinicas
                :cidadeFuncionario="funcionario.cidade"
                :clinicaFuncionario="funcionario.clinica"
                :funcionario="funcionario"
                ref="AgendamentoClinica"
                v-if="step === 2"
                @clinica-selected="resolveClinicaSelected"
                @on-click-cidade="previousStep()"
                @valid-date="checkValidDate"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="justify-space-between jus">
        <v-btn color="primary" large rounded @click="previousStep()" :disabled="step === 1">
          <v-icon>mdi-chevron-left</v-icon>
          Anterior
        </v-btn>
        <v-btn color="primary" large rounded @click="nextStep()" :disabled="step === 2 && !validDate">
          {{ step === 2 ? 'Confirmar' : 'Próximo' }}
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AgendamentoCidades from '@/components/Agendamento/AgendamentoCidades'
import AgendamentoClinicas from '@/components/Agendamento/AgendamentoClinicas'

export default {
  name: 'EscolherClinicaDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    funcionario: {
      required: true,
      type: Object,
    },
  },
  components: {
    AgendamentoCidades,
    AgendamentoClinicas,
  },
  data() {
    return {
      step: 1,
      validDate: false,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    nextStep() {
      if (this.step === 2) {
        this.$emit('confirm-clinica')
      } else if (
        this.$refs.AgendamentoCidade.$refs.AgendamentoCidadeForm.validate() &&
        this.$refs.AgendamentoCidade.verificaCidadeSemClinicas()
      ) {
        this.step = 2
      }
    },
    resolveClinicaSelected(event) {
      this.$emit('clinica-selected', event)
      this.nextStep()
    },
    previousStep() {
      this.step--
    },
    checkValidDate(val) {
      if (val) {
        this.validDate = true
      } else {
        this.validDate = false
      }
    },
  },
}
</script>
