<template>
  <div>
    <v-row justify="center">
      <v-progress-circular v-if="isLoading" indeterminate color="primary" size="20" class="mx-5"> </v-progress-circular>
      <span v-if="isLoading" class="indeterminate-bar-text"
        >Aguarde enquanto consultamos nossa Inteligência Artificial...</span
      >
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="article" v-if="isLoading"></v-skeleton-loader>
        <div v-else>
          <div class="mb-5 funcao highlighted">
            <span class="label">Exames da função</span>
            <span class="capitalize text-link" @click="$emit('change-step', 2)">
              {{ funcionario.funcao.toLowerCase() }}
            </span>
          </div>
          <ExamesList :exames="funcionario.exames" @delete-exame="deletarExame" />
          <v-row no-gutters justify="center">
            <v-btn text color="primary" class="capitalize mt-2" @click="examesDialog = true">Adicionar exames</v-btn>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader type="article" v-if="isLoading"></v-skeleton-loader>
        <div v-else>
          <div class="mb-5">
            Exames do setor
            <span class="capitalize text-link" @click="$emit('change-step', 2)">
              {{ funcionario.setor.toLowerCase() }}
            </span>
          </div>
          <div class="protocolo-list">
            <div class="protocolo-item" v-if="funcionario.riscos && funcionario.riscos.length === 0">
              Esse setor não possui nenhum risco associado...
            </div>
            <template v-else>
              <div class="protocolo-item" v-for="risco in funcionario.riscos" :key="risco.id_riscos">
                <div>
                  <v-icon size="25" color="#e3e3e3">mdi-alert-circle-outline</v-icon>
                </div>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div v-on="on" class="risco">
                      {{ risco.nome }}
                    </div>
                  </template>
                  <div>
                    {{ risco.nome }}
                  </div>
                </v-tooltip>
              </div>
            </template>
          </div>
          <v-row no-gutters justify="center">
            <v-btn text color="primary" class="capitalize mt-2" @click="riscosDialog = true">Adicionar riscos</v-btn>
          </v-row>
        </div>
      </v-col>
      <ExamesDialog
        v-if="examesDialog"
        @add-exames="addExames"
        v-model="examesDialog"
        @close="examesDialog = false"
        :idExamesSelecionados="idExamesSelecionados"
      />
      <RiscosDialog
        v-if="riscosDialog"
        @add-riscos="addRiscos"
        v-model="riscosDialog"
        @close="riscosDialog = false"
        :idRiscosSelecionados="idRiscosSelecionados"
      />
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapGetters } from 'vuex'
import agendamentoService from '@/services/agendamento'
import { TIPOS_EXAME } from '@/utils/constants'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import ExamesList from './ExamesList.vue'
import ExamesDialog from '../dialogs/ExamesDialog.vue'
import RiscosDialog from '../dialogs/RiscosDialog.vue'

export default {
  props: {
    funcionario: {
      required: true,
    },
  },
  mixins: [agendamentoMixin],
  components: { ExamesDialog, RiscosDialog, ExamesList },
  computed: {
    ...mapGetters('agendamento', ['clinica']),
    idExamesSelecionados() {
      return this.funcionario.exames ? this.funcionario.exames.map(exame => exame.id_exame) : []
    },
    idRiscosSelecionados() {
      return this.funcionario.riscos ? this.funcionario.riscos.map(risco => risco.id_risco) : []
    },
  },
  data: () => ({
    examesDialog: false,
    riscosDialog: false,
    exames: [],
    riscos: [],
    isLoading: false,
    deleteDialog: false,
    deleteExame: false,
  }),
  methods: {
    async montaProtocolo() {
      if (!(this.funcionarioHasExames() && this.funcionarioHasRiscos())) {
        const { funcao, cbo, setor, questionario } = this.funcionario
        const exame = TIPOS_EXAME.find(exame => exame.slug === this.funcionario.exame).slug
        this.isLoading = true
        try {
          const { data } = await agendamentoService.protocolo(exame, funcao, cbo, setor, questionario, this.clinica)

          this.funcionario.exames = data.exames

          if (!this.funcionarioHasRiscos()) {
            this.funcionario.riscos = data.riscos
          }

          this.funcionario.riscos = data.riscos
        } catch ({ response }) {
          this.showErrorOnSnackbar(response.data.message)
        } finally {
          this.isLoading = false
        }
      }
    },
    funcionarioHasExames() {
      return this.funcionario.exames && this.funcionario.exames.length > 0
    },
    funcionarioHasRiscos() {
      return this.funcionario.riscos && this.funcionario.riscos.length > 0
    },
    addExames(exames) {
      exames.forEach(exame => {
        if (exame.id) {
          exame.id_exame = exame.id
        }
      })
      this.funcionario.exames = this.funcionario.exames.concat(exames)
      this.examesDialog = false
    },
    addRiscos(riscos) {
      this.funcionario.riscos = this.riscos.concat(riscos)
      this.riscosDialog = false
    },
    openDeleteDialog(exame) {
      if (exame.isAdded) {
        this.deleteExame = exame
        this.deleteDialog = true
      }
    },
    deletarExame(exameDelete) {
      const index = this.funcionario.exames.findIndex(exame => exame.id_exame === exameDelete.id_exame)
      this.funcionario.exames.splice(index, 1)
    },
  },
  mounted() {
    this.montaProtocolo()
  },
  beforeMount() {
    this.actualStepWrapper = 5
  },
}
</script>
<style scoped>
.highlighted {
  color: #005dff;
}
.protocolo-list {
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
}
.protocolo-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 5px 0px;
}

.risco {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0px 10px;
  color: #005dff;
  font-weight: 700;
}

.funcao {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.total-price {
  margin-top: 20px;
  font-size: 0.9rem;
}
.label {
  color: rgba(0, 0, 0, 0.87);
}

.indeterminate-bar-text {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 12px;
}
</style>
