<template>
  <v-dialog
      v-model="value"
      persistent
      max-width="500px"
      transition="dialog-transition"
  >
      <v-card>
          <v-card-title primary-title>
              {{ title }}
          </v-card-title>
          <v-card-text>
            {{ text }}
          </v-card-text>
          <v-card-actions class="justify-end">
              <v-btn color="error" @click="$emit('cancel-button')">Cancelar</v-btn>
              <v-btn color="success" @click="$emit('confirm-button')">Confirmar</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        title: {
            required: true,
        },
        text: {
            required: true,
        },
    }
}
</script>

<style>

</style>
